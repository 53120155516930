// tareas-servicios.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { TareaServicio } from '../models/tarea-servicio.model';

@Injectable({
  providedIn: 'root'
})
export class TareasServiciosService {
  private baseUrl: string = environment.baseUrl + '/api/tareas-servicio';

  constructor(private http: HttpClient) {}

  getAll(): Observable<TareaServicio[]> {
    return this.http.get<TareaServicio[]>(this.baseUrl);
  }

  getById(tipoUnidad: string, idUnidad: number, idTarea: number, idServicio: number): Observable<TareaServicio> {
    return this.http.get<TareaServicio>(`${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idTarea}/${idServicio}`);
  }

  getTareasByUnidad(tipoUnidad: string, idUnidad: number): Observable<TareaServicio[]> {
    return this.http.get<TareaServicio[]>(`${this.baseUrl}/unidad/${tipoUnidad}/${idUnidad}`);
  }

  getTareasByServicio(idServicio: number): Observable<TareaServicio[]> {
    return this.http.get<TareaServicio[]>(`${this.baseUrl}/servicio/${idServicio}`);
  }

  create(tarea: TareaServicio): Observable<TareaServicio> {
    return this.http.post<TareaServicio>(this.baseUrl, tarea);
  }

  update(tipoUnidad: string, idUnidad: number, idTarea: number, idServicio: number, tarea: TareaServicio): Observable<TareaServicio> {
    return this.http.put<TareaServicio>(`${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idTarea}/${idServicio}`, tarea);
  }

  delete(tipoUnidad: string, idUnidad: number, idTarea: number, idServicio: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idTarea}/${idServicio}`);
  }
}
