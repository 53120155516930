// src/app/unidades/unidades.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';

// Importar PrimeNG y Tailwind CSS según sea necesario
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card'; // Importar CardModule
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AutoCompleteModule } from 'primeng/autocomplete';




// Componentes
// import { UnidadesListComponent } from './unidades-list/unidades-list.component';
// import { UnidadFormComponent } from './unidad-form/unidad-form.component';
import { UnidadCrudComponent } from './unidad-crud/unidad-crud.component';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [UnidadCrudComponent
    // , UnidadesListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // PrimeNG Modules
    AutoCompleteModule,
    TableModule,
    ConfirmDialogModule,
    ButtonModule, // Agrega ButtonModule aquí
    InputTextModule,
    CalendarModule,
    MessagesModule,
    MessageModule,
    DropdownModule,
    CardModule,
    ToastModule,
    DialogModule
  ],
  providers: [MessageService, ConfirmationService],
  exports: [
    UnidadCrudComponent
    // , UnidadesListComponent
  ]
})
export class UnidadesModule {}
