<!-- acuerdos.component.html -->
<div class="card p-4">
  <p-toast></p-toast>
  <p-confirmDialog></p-confirmDialog>

  <div class="flex justify-between items-center mb-4">
      <h2 class="text-2xl font-bold">Gestión de Acuerdos</h2>
      <button pButton label="Nuevo Acuerdo" icon="pi pi-plus" class="p-button-primary" (click)="showDialog()"></button>
  </div>

  <p-table
      #dt
      [value]="acuerdos"
      [rows]="10"
      [paginator]="true"
      [tableStyle]="{'min-width': '75rem'}"
      [loading]="loading"
      [rowHover]="true"
      dataKey="tipoAcuerdo"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
      [rowsPerPageOptions]="[10,25,50]">

      <ng-template pTemplate="header">
          <tr>
              <th pSortableColumn="tipoAcuerdo">Tipo de Acuerdo <p-sortIcon field="tipoAcuerdo"></p-sortIcon></th>
              <th pSortableColumn="descripcion">Descripción <p-sortIcon field="descripcion"></p-sortIcon></th>
              <th>Acciones</th>
          </tr>
          <tr>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'tipoAcuerdo', 'contains')"
                         placeholder="Buscar por tipo" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'descripcion', 'contains')"
                         placeholder="Buscar por descripción" class="p-column-filter w-full">
              </th>
              <th></th>
          </tr>
      </ng-template>

      <ng-template pTemplate="body" let-acuerdo>
          <tr>
              <td>{{acuerdo.tipoAcuerdo}}</td>
              <td>{{acuerdo.descripcion}}</td>
              <td>
                  <div class="flex gap-2">
                      <button pButton icon="pi pi-pencil" class="p-button-rounded p-button-warning p-button-sm"
                              (click)="showDialog(acuerdo)"></button>
                      <button pButton icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-sm"
                              (click)="deleteAcuerdo(acuerdo)"></button>
                  </div>
              </td>
          </tr>
      </ng-template>
  </p-table>

  <p-dialog [(visible)]="dialogVisible" [style]="{width: '50vw'}" [modal]="true"
            [header]="isNew ? 'Nuevo Acuerdo' : 'Editar Acuerdo'">
      <div class="grid grid-cols-1 gap-4">
          <div class="col-span-1">
              <label class="block mb-2">Tipo de Acuerdo *</label>
              <input pInputText [(ngModel)]="acuerdo.tipoAcuerdo" class="w-full" [disabled]="!isNew" required />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Descripción *</label>
              <input pInputText [(ngModel)]="acuerdo.descripcion" class="w-full" required />
          </div>
      </div>

      <ng-template pTemplate="footer">
          <button pButton label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="dialogVisible = false"></button>
          <button pButton label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveAcuerdo()"></button>
      </ng-template>
  </p-dialog>
</div>
