import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service'; // Ajusta la ruta según tu estructura

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Obtener el token actual
    const token = this.authService.getToken();

    // Si hay token, añadirlo al header
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}` // Fixed the syntax error by adding backticks
        }
      });
    }

    // Procesar la petición y verificar la respuesta
    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Verificar si hay un nuevo token en los headers
            const newToken = event.headers.get('New-Token');
            if (newToken) {
              console.debug('Token renovado recibido');
              this.authService.updateToken(newToken);
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          // Si el error es 401 o 403, podría significar que el token expiró
          if (error.status === 401 || error.status === 403) {
            console.debug('Error de autenticación detectado');
            // Si no estamos en la página de login, redirigir
            if (!window.location.href.includes('/login')) {
              this.authService.logout();
            }
          }
        }
      })
    );
    }
}
