<!-- tareas-servicios.component.html -->
<div class="card p-4">
  <p-toast></p-toast>
  <p-confirmDialog></p-confirmDialog>

  <div class="flex justify-between items-center mb-4">
      <h2 class="text-2xl font-bold">Gestión de Tareas de Servicios</h2>
      <button pButton label="Nueva Tarea Servicio" icon="pi pi-plus" class="p-button-primary" (click)="showDialog()"></button>
  </div>

  <p-table
      #dt
      [value]="tareasServicios"
      [rows]="10"
      [paginator]="true"
      [tableStyle]="{'min-width': '75rem'}"
      [loading]="loading"
      [rowHover]="true"
      dataKey="idTarea"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
      [rowsPerPageOptions]="[10,25,50]">

      <ng-template pTemplate="header">
          <tr>
              <th pSortableColumn="tipoUnidad">Tipo Unidad <p-sortIcon field="tipoUnidad"></p-sortIcon></th>
              <th pSortableColumn="idUnidad">ID Unidad <p-sortIcon field="idUnidad"></p-sortIcon></th>
              <th pSortableColumn="idTarea">ID Tarea <p-sortIcon field="idTarea"></p-sortIcon></th>
              <th pSortableColumn="idServicio">ID Servicio <p-sortIcon field="idServicio"></p-sortIcon></th>
              <th pSortableColumn="tipoAplicacion">Tipo Aplicación <p-sortIcon field="tipoAplicacion"></p-sortIcon></th>
              <th>Acciones</th>
          </tr>
          <tr>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'tipoUnidad', 'contains')"
                         placeholder="Buscar por tipo" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'idUnidad', 'contains')"
                         placeholder="Buscar por ID" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'idTarea', 'contains')"
                         placeholder="Buscar por ID" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'idServicio', 'contains')"
                         placeholder="Buscar por ID" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'tipoAplicacion', 'contains')"
                         placeholder="Buscar por tipo" class="p-column-filter w-full">
              </th>
              <th></th>
          </tr>
      </ng-template>

      <ng-template pTemplate="body" let-tareaServicio>
          <tr>
              <td>{{tareaServicio.tipoUnidad}}</td>
              <td>{{tareaServicio.idUnidad}}</td>
              <td>{{tareaServicio.idTarea}}</td>
              <td>{{tareaServicio.idServicio}}</td>
              <td>{{tareaServicio.tipoAplicacion}}</td>
              <td>
                  <div class="flex gap-2">
                      <button pButton icon="pi pi-pencil" class="p-button-rounded p-button-warning p-button-sm"
                              (click)="showDialog(tareaServicio)"></button>
                      <button pButton icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-sm"
                              (click)="deleteTareaServicio(tareaServicio)"></button>
                  </div>
              </td>
          </tr>
      </ng-template>
  </p-table>

  <p-dialog [(visible)]="dialogVisible" [style]="{width: '50vw'}" [modal]="true"
            [header]="isNew ? 'Nueva Tarea Servicio' : 'Editar Tarea Servicio'">
      <div class="grid grid-cols-2 gap-4">
          <div class="col-span-1">
              <label class="block mb-2">Tipo de Unidad *</label>
              <p-dropdown [options]="tipoUnidadOptions" [(ngModel)]="tareaServicio.tipoUnidad"
            [disabled]="!isNew" placeholder="Seleccione tipo"
            class="w-full rounded-lg"></p-dropdown>
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Unidad *</label>
              <p-dropdown [options]="catalogo" [(ngModel)]="tareaServicio.idUnidad"
                        [disabled]="!isNew" placeholder="Seleccione unidad" class="w-full"></p-dropdown>
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Tarea *</label>
              <p-dropdown [options]="tareas" [(ngModel)]="tareaServicio.idTarea"
                        [disabled]="!isNew" placeholder="Seleccione tarea" class="w-full"></p-dropdown>
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Servicio *</label>
              <p-dropdown [options]="serviciosGarantiaOptions" [(ngModel)]="tareaServicio.idServicio"
                        placeholder="Seleccione servicio" class="w-full"></p-dropdown>
          </div>
          <div class="col-span-2">
              <label class="block mb-2">Tipo de Aplicación *</label>
              <input pInputText [(ngModel)]="tareaServicio.tipoAplicacion" class="w-full" maxlength="1" required />
          </div>
      </div>

      <ng-template pTemplate="footer">
          <button pButton label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="dialogVisible = false"></button>
          <button pButton label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveTareaServicio()"></button>
      </ng-template>
  </p-dialog>
</div>
