import { TitularService } from './../../services/titular.service';
import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UnidadVendida } from '../../models/unidades-vendidas.model';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Unidad } from '../../models/unidad-maestro.model';
import { UnidadService } from '../../services/unidad-maestro.service';
import { UnidadesVendidasService } from '../../services/unidades-vendidas.service';
import { Titular } from '../../models/titular.model';
import { AgenciasService } from '../../services/agencias.service';
import { Agencia } from '../../models/agencias.model';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, forkJoin, of, switchMap, tap } from 'rxjs';
import { BaseComponent } from '../../base/base.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-unidades-vendidas',
  templateUrl: './unidades-vendidas.component.html',
  styleUrls: ['./unidades-vendidas.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class UnidadesVendidasComponent extends BaseComponent implements OnInit  {

  unidadesVendidas: UnidadVendida[] = [];
  selectedUnidadVendida: UnidadVendida | null = null;
  unidadVendidaForm: FormGroup;
  isCreating: boolean = false;
  deleteError: string | null = null;
  deleteSuccess: string | null = null;
  isEditing: boolean = false;
  vinSearch: string = '';
  vinsCargados: string[] = [];
  dominiosCargados: string[] = [];
  dominioSearch: string = '';

  unidades: Unidad[] = [];
  selectedUnidad: Unidad | null = null;
  agencias: { label: string, value: any }[] = [];
  isCreateMode: boolean = false;

  isVinSearch: boolean = false;
  isDominioSearch: boolean = false;

  isLoading: boolean = false;


  //genera un array con las opciones de provincias argentinas
  provinciasOptions: string[] = [
    'Buenos Aires',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Córdoba',
    'Corrientes',
    'Entre Ríos',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquén',
    'Río Negro',
    'Salta',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
    'Tucumán'
  ];


  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    // private errorHandler: ErrorHandlerService,
    private unidadesVendidasService: UnidadesVendidasService,
    private agenciasService: AgenciasService,
    private confirmationService: ConfirmationService,
    private unidadService: UnidadService,
    private titularService: TitularService
  ) {
    super();

    this.unidadVendidaForm = this.fb.group({
      dominio: new FormControl({ value: '', disabled: true }, Validators.required),
      vin: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(24)]),
      tipoUnidad: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(3)]),
      idUnidad: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(20)]),
      nroMotor: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(24)]),
      idAgencia: new FormControl({ value: null, disabled: true }, Validators.required),
      kmEntrega: new FormControl({ value: '', disabled: true }, Validators.required),
      kmGarantia: new FormControl({ value: '', disabled: true }, Validators.required),
      fechaGarantiaDate: new FormControl({ value: '', disabled: true }, Validators.required),
      fechaVentaDate: new FormControl({ value: '', disabled: true }, Validators.required),
      titulares: this.fb.array([])
    });
  }

  get titulares(): FormArray {
    return this.unidadVendidaForm.get('titulares') as FormArray;
  }

  addTitular(): void {
    console.log('isEditing:', this.isEditing);
    console.log('isCreating:', this.isCreating);

    const titularForm = this.fb.group({
      dominio: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      fechaTitularidadDate: [
        {
          value: '',
          disabled: !this.isEditing && !this.isCreating
        },
        Validators.required
      ],
      nombre: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      cuitCuil: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      // movil: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      correo: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      direccion: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      calle: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      numero: [null, Validators.required],
      provincia: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      localidad: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      fechaVentaDate: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      codPostal: ['', Validators.required]
    });
    this.titulares.push(titularForm);
  }


  removeTitular(index: number): void {
    this.titulares.removeAt(index);
  }

  ngOnInit(): void {
    this.route.params.pipe(
      tap(params => this.initializeComponent(params))
    ).subscribe();
  }

  private async initializeComponent(params: any): Promise<void> {
    this.isLoading = true;

    try {
      await Promise.all([
        this.loadUnidadesVendidas(),
        this.loadMaestroUnidades(),
        this.loadAgencias()
      ]);

      this.isVinSearch = this.route.snapshot.url.some(segment => segment.path === 'vin');
      this.isDominioSearch = this.route.snapshot.url.some(segment => segment.path === 'dominio');

      if (this.isDominioSearch) {
        if (!params['dominio']) {
          console.error('Error: No hay dominio en la ruta');
          return;
        }
        this.dominioSearch = params['dominio'];
        this.searchUnidadVendidaDominio(this.dominioSearch);
      }

      if (this.isVinSearch) {
        if (!params['vin']) {
          console.error('Error: No hay VIN en la ruta');
          return;
        }

        this.vinSearch = params['vin'];
        await this.searchUnidadVendidaVin(this.vinSearch);

        if (!this.selectedUnidadVendida) {
          this.nuevoRegistro();

          await new Promise<void>((resolve) => {
            setTimeout(async () => {
              try {
                const unidadArray = await this.unidadService.getUnidadesByVin(this.vinSearch).toPromise();

                if (unidadArray && unidadArray.length > 0) {
                  this.selectedUnidad = unidadArray[0];

                  if (this.unidadVendidaForm) {
                    const unidadData = {
                      vin: this.selectedUnidad.vin,
                      tipoUnidad: this.selectedUnidad.tipoUnidad,
                      idUnidad: this.selectedUnidad.idUnidad,
                      nroMotor: this.selectedUnidad.nroMotor
                    };

                    this.unidadVendidaForm.patchValue(unidadData, { emitEvent: false });
                    this.deshabilitarExtraAttrs();

                    Object.keys(unidadData).forEach(key => {
                      const control = this.unidadVendidaForm.get(key);
                      if (control) {
                        control.markAsTouched();
                        control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                      }
                    });
                  }

                  this.cdr.detectChanges(); // Forzar la detección de cambios para actualizar la vista
                }
                resolve();
              } catch (error) {

                this.handleError(error as HttpErrorResponse, {
                  defaultMessage: 'Error al cargar datos de la unidad',
                  severity: 'error'
                });

                resolve();
              }
            }, 0);
          });

          this.cdr.detectChanges(); // Forzar la detección de cambios para actualizar la vista
        }
      }

      // this.isInitialized = true;
    } catch (error) {
      console.error('Error en initializeComponent:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error al inicializar el componente'
      });
    } finally {
      this.isLoading = false;
    }
  }


  loadAgencias(): void {
    this.agenciasService.getAll().pipe(
      tap((data: Agencia[]) => {
        this.agencias = data.map(agencia => ({
          label: agencia.nombre,
          value: agencia.idAgencia
        }));
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar agencias' });
        return of([]);
      })
    ).subscribe();
  }

  loadMaestroUnidades(): void {
    this.unidadService.getUnidades().pipe(
      tap((data: Unidad[]) => {
        this.unidades = data;
        this.vinsCargados = this.unidades.map(unidad => unidad.vin);
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades' });
        return of([]);
      })
    ).subscribe();
  }

  loadUnidadesVendidas(): void {
    this.unidadesVendidasService.getAllUnidadesVendidas().pipe(
      tap((data: UnidadVendida[]) => {
        this.unidadesVendidas = data;
        this.dominiosCargados = this.unidadesVendidas.map(unidad => unidad.dominio);
        console.log('dominiosCargados:', this.dominiosCargados);
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades vendidas' });
        return of([]);
      })
    ).subscribe();
  }

  searchVin(event: AutoCompleteCompleteEvent): void {
    const query = event.query;
    this.vinsCargados = this.unidades.filter(unidad => unidad.vin.toLowerCase().startsWith(query.toLowerCase())).map(unidad => unidad.vin);
  }

  searchDominioIncremental(event: AutoCompleteCompleteEvent): void {
    const query = event.query;
    this.dominiosCargados = this.unidadesVendidas.filter(unidad => unidad.dominio.toLowerCase().startsWith(query.toLowerCase())).map(unidad => unidad.dominio);
  }


  searchUnidadAndLoadAttrs(event: any): void {
    const vin = event.value || event.query || event;
    if (!vin) {
      console.error('No se ha ingresado un VIN');
      return;
    }

    this.unidadService.getUnidadesByVin(vin).pipe(
      tap((unidadArray: Unidad[]) => {
        if (unidadArray && unidadArray.length > 0) {
          this.selectedUnidad = unidadArray[0];
          this.unidadVendidaForm.patchValue({
            vin: this.selectedUnidad.vin,
            tipoUnidad: this.selectedUnidad.tipoUnidad,
            idUnidad: this.selectedUnidad.idUnidad,
            nroMotor: this.selectedUnidad.nroMotor
          });
        } else {
          console.warn('No se encontró ninguna unidad para el vin ', vin);
        }
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unidad no encontrada' });
        return of([]);
      })
    ).subscribe();
  }


  searchUnidadVendida(event: any): void {
    const vin = event.value;
    console.log('event: ', event);

    // this.messageService.add({ severity: 'success', summary: 'Debug', detail: 'vin=' + vin });
    this.searchUnidadVendidaVin(vin);
  }

  searchUnidadVendidaByDominio(event: any): void {
    const vin = event.value;
    console.log('event: ', event);

    this.searchUnidadVendidaDominio(vin);
  }

  nuevoRegistro(): void {
    this.unidadVendidaForm.reset();
    this.isEditing = true;
    this.isCreating = true;
    this.selectedUnidad = null;
    this.enableForm();

    this.deshabilitarExtraAttrs();
  }

  searchUnidadVendidaVin(pvin?: string): void {
    const vin = pvin || this.vinSearch;

    this.unidadesVendidasService.searchUnidadesVendidas(vin).pipe(
      tap((data: UnidadVendida[]) => {
        if (data.length > 0) {
          this.selectedUnidadVendida = data[0];
          this.unidadVendidaForm.patchValue(this.selectedUnidadVendida);

          if (this.selectedUnidadVendida) {
            this.loadTitulares(this.selectedUnidadVendida.dominio);
          }

          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();

          window.scrollTo(0, 0);
        } else {
          this.selectedUnidadVendida = null;
          this.unidadVendidaForm.reset();
          console.warn('No se encontró ninguna unidad vendida para el vin (searchUnidadVendidaVin)', vin);
        }
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al buscar unidad vendida' });
        return of([]);
      })
    ).subscribe();
  }

  searchUnidadVendidaDominio(pdominio?: string): void {
    const dominio = pdominio || this.dominioSearch;

    this.unidadesVendidasService.searchUnidadesVendidas(dominio).pipe(
      tap((data: UnidadVendida[]) => {
        if (data.length > 0) {
          this.selectedUnidadVendida = data[0];
          this.unidadVendidaForm.patchValue(this.selectedUnidadVendida);

          if (this.selectedUnidadVendida) {
            this.loadTitulares(this.selectedUnidadVendida.dominio);
          }

          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();

          window.scrollTo(0, 0);
        } else {
          this.selectedUnidadVendida = null;
          this.unidadVendidaForm.reset();
          console.warn('No se encontró ninguna unidad.');
        }
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al buscar unidad vendida' });
        return of([]);
      })
    ).subscribe();
  }

  private loadTitulares(dominio: string): void {
    this.titularService.getTitularesByDominio(dominio).pipe(
      tap((titulares: Titular[]) => {
        this.titulares.clear();
        titulares.forEach(titular => {
          const titularForm = this.fb.group({
            fechaTitularidadDate: [{ value: titular.fechaTitularidadDate, disabled: true }],
            nombre: [{ value: titular.nombre, disabled: true }],
            cuitCuil: [{ value: titular.cuitCuil, disabled: true }],
            correo: [{ value: titular.correo, disabled: true }],
            direccion: [{ value: titular.direccion, disabled: true }],
            calle: [{ value: titular.calle, disabled: true }],
            numero: [{ value: titular.numero, disabled: true }],
            provincia: [{ value: titular.provincia, disabled: true }],
            localidad: [{ value: titular.localidad, disabled: true }],
            codPostal: [{ value: titular.codPostal, disabled: true }]
          });
          this.titulares.push(titularForm);
        });
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar titulares' });
        return of([]);
      })
    ).subscribe();
  }

  createUnidadVendida(): void {

    let creatingVin = this.unidadVendidaForm.get('vin')?.value;

    // Ensure the values are set before validation
    this.unidadVendidaForm.get('tipoUnidad')?.setValue(this.selectedUnidad?.tipoUnidad);
    this.unidadVendidaForm.get('idUnidad')?.setValue(this.selectedUnidad?.idUnidad);
    this.unidadVendidaForm.get('nroMotor')?.setValue(this.selectedUnidad?.nroMotor);

    if (this.isNotValidAndCompleteForm()) {
      return;
    }
    if (this.unidadVendidaForm.valid) {

      this.unidadesVendidasService.createUnidadVendida(this.unidadVendidaForm.getRawValue()).subscribe({
        next: () => {

          this.createTitularesAndOptionallyUpdateUnidad(false);

          const newUnidadVendida = this.unidadVendidaForm.getRawValue() as UnidadVendida;
          this.unidadesVendidas.push(newUnidadVendida);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Unidad Vendida creada exitosamente' });

          this.loadUnidadesVendidas();
          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();
          this.searchUnidadVendidaVin(creatingVin);
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    }
  }

  deshabilitarExtraAttrs() : void {
    this.unidadVendidaForm.get('nroMotor')?.disable();
    this.unidadVendidaForm.get('nroMotor')?.updateValueAndValidity();
    this.unidadVendidaForm.get('tipoUnidad')?.disable();
    this.unidadVendidaForm.get('tipoUnidad')?.updateValueAndValidity();
    this.unidadVendidaForm.get('idUnidad')?.disable();
    this.unidadVendidaForm.get('idUnidad')?.updateValueAndValidity();
  }

  callHistorialServicios(): void {
    this.router.navigate(['/historial-servicio/', this.selectedUnidadVendida?.dominio]);
  }

  callMaestroUnidades(): void {
    this.router.navigate(['/maestro-unidades/', this.selectedUnidadVendida?.vin]);
  }

  editarUnidadVendida(): void {
    if (this.selectedUnidadVendida) {
      this.isEditing = true;
      this.isCreating = false;
      this.enableForm();

      this.unidadVendidaForm.get('nroMotor')?.disable();
      this.unidadVendidaForm.get('nroMotor')?.updateValueAndValidity();
      this.unidadVendidaForm.get('tipoUnidad')?.disable();
      this.unidadVendidaForm.get('tipoUnidad')?.updateValueAndValidity();
      this.unidadVendidaForm.get('idUnidad')?.disable();
      this.unidadVendidaForm.get('idUnidad')?.updateValueAndValidity();
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Seleccione una unidad vendida para editar' });
    }
  }

  isNotValidAndCompleteForm(): boolean {

    let faltanCampos=false;

    // Log de errores de cada control antes de guardar
    Object.keys(this.unidadVendidaForm.controls).forEach(key => {
      const controlErrors = this.unidadVendidaForm.get(key)?.errors;
      if (controlErrors) {
        console.log(`Errores en el campo ${key}:`, controlErrors);

        //Marca el input como ng-dirty o algo similar
        this.unidadVendidaForm.get(key)?.markAsDirty();

        faltanCampos=true;
      }
    });


    // Log de errores de cada titular antes de guardar
    this.titulares.controls.forEach((titularForm, index) => {
      Object.keys((titularForm  as FormGroup).controls).forEach(key => {
        const controlErrors = titularForm.get(key)?.errors;
        // console.log('Control:', key, 'Errores:', controlErrors, 'Valor:', titularForm.get(key)?.value);

        if (controlErrors) {
          // console.log(`Errores en el campo ${key}:`, controlErrors);

          //Marca el input como ng-dirty o algo similar
          titularForm.get(key)?.markAsDirty();
          titularForm.get(key)?.markAsTouched();

          faltanCampos=true;
        }
      });
    });

    if(faltanCampos){
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Formulario inválido: Complete los campos obligatorios'});
    }

    return faltanCampos;
  }


  private callUpdateUnidadVendida(): void {
    this.unidadesVendidasService.updateUnidadVendida(
      this.selectedUnidadVendida!.dominio,
      this.unidadVendidaForm.getRawValue()
    ).subscribe({
      next: () => {
        this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Unidad Vendida actualizada exitosamente' });
        this.loadUnidadesVendidas();
        this.searchUnidadVendidaVin(this.unidadVendidaForm.get('vin')?.value);
        this.isEditing = false;
        this.isCreating = false;
        this.disableForm();
      },
      error: (error) => {
        console.error('Error al actualizar unidad vendida:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar unidad vendida' });
      }
    });
  }


  updateUnidadVendida(): void {

    // Ensure the values are set before validation
    this.unidadVendidaForm.get('tipoUnidad')?.setValue(this.selectedUnidadVendida?.tipoUnidad);
    this.unidadVendidaForm.get('idUnidad')?.setValue(this.selectedUnidadVendida?.idUnidad);
    this.unidadVendidaForm.get('nroMotor')?.setValue(this.selectedUnidadVendida?.nroMotor);

    if (this.isNotValidAndCompleteForm()) {
      return;
    }

    if (this.unidadVendidaForm.valid && this.selectedUnidadVendida) {

      if (this.titulares.length > 0) {
        // Delete existing titulares and recreate them
        this.titularService.deleteTitularesByDominio(this.unidadVendidaForm.get('dominio')?.value).subscribe({
          next: () => {
            console.log('Titulares eliminados exitosamente');
            this.createTitularesAndOptionallyUpdateUnidad(true);
          },
          error: (error) => {
            if (error.status === 404) {
              console.warn('No existen titulares previos, continuando con la creación.');
              this.createTitularesAndOptionallyUpdateUnidad(true);
            } else {
              console.error('Error al eliminar titulares:', error);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al eliminar titulares' });
            }
          }
        });
      } else {
        // If no titulares, directly update unidad vendida
        this.callUpdateUnidadVendida();
      }
    }
  }


  private createTitularesAndOptionallyUpdateUnidad(execUpdateUnidad?: boolean): void {
    const createTitularesObservables = this.titulares.controls.map(titularForm => {
      const titular: Titular = {
        dominio: this.unidadVendidaForm.get('dominio')?.value,
        ...titularForm.getRawValue()
      };

      titular.dominio = this.unidadVendidaForm.get('dominio')?.value;
      console.log(titular);

      return this.titularService.createTitular(titular);
    });

    forkJoin(createTitularesObservables).subscribe({
      next: () => {
        this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Titulares creados exitosamente' });

        if (execUpdateUnidad) {
          this.callUpdateUnidadVendida();
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al crear el titular',
          severity: 'error'
        });
      }
    });
  }



  deleteUnidadVendida(): void {
    if (this.selectedUnidadVendida) {
      this.confirmationService.confirm({
        message: '¿Está seguro de que desea eliminar esta unidad vendida?',
        accept: () => {
          const dominio = this.unidadVendidaForm.get('dominio')?.value;

          this.titularService.deleteTitularesByDominio(dominio).pipe(
            catchError(error => {
              console.log('Error al eliminar titular:', error);
              // Manejar el error si es necesario, pero continuar el flujo
              return of(null); // Retornar un observable vacío para continuar
            }),
            switchMap(() =>
              this.unidadesVendidasService.deleteUnidadVendida(this.selectedUnidadVendida!.dominio)
            )
          ).subscribe({
            next: () => {
              this.unidadesVendidas = this.unidadesVendidas.filter(u => u !== this.selectedUnidadVendida);
              this.selectedUnidadVendida = null;
              this.deleteSuccess = 'Unidad Vendida eliminada exitosamente';
              this.messageService.add({ severity: 'success', summary: 'Éxito', detail: this.deleteSuccess });
              this.loadUnidadesVendidas();
              this.unidadVendidaForm.reset();
              this.titulares.clear();
              this.isEditing = false;
              this.selectedUnidad = null;
              this.disableForm();
            },
            error: (error) => {
              this.handleError(error, { defaultMessage: 'Error al eliminar unidad vendida', severity: 'error' });
            }
          });
        },
        reject: () => {
          this.deleteError = 'Eliminación cancelada';
        }
      });
    }
  }


  cancelEdit(): void {
    this.unidadVendidaForm.reset();
    this.isEditing = false;
    this.isCreating = false;
    this.selectedUnidadVendida = null;
    this.selectedUnidad = null;
    this.disableForm();
  }

  consultarUnidadVendida(unidadVendida: UnidadVendida): void {
    this.vinSearch = unidadVendida.vin;
    this.searchUnidadVendidaVin(unidadVendida.vin);
  }

  enableForm(): void {
    Object.keys(this.unidadVendidaForm.controls).forEach(controlName => {
      const control = this.unidadVendidaForm.get(controlName);
      control?.enable();
      control?.updateValueAndValidity(); // Asegúrate de actualizar la validez
    });
    Object.keys(this.unidadVendidaForm.controls).forEach(controlName => {
      this.unidadVendidaForm.get(controlName)?.enable();
    });
  }

  disableForm(): void {
    Object.keys(this.unidadVendidaForm.controls).forEach(controlName => {
      this.unidadVendidaForm.get(controlName)?.disable();
    });
  }

}
