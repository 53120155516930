// src/app/app.component.ts
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from './services/auth.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-root',
  animations: [
    trigger('menuAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(-20px)' }))
      ])
    ]),
    trigger('logoAnimation', [
      transition(':enter', [
        style({ opacity: 0, scale: 0.8 }),
        animate('500ms ease-out', style({ opacity: 1, scale: 1 }))
      ])
    ])
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  items: MenuItem[] = [];
  title = 'Gestor de unidades';
  userName: string | null = null;
  roles: string[] = [];
  rolesDescription: String | null = null;
  isUserLoggedIn: boolean = false;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    // Exponer la función getToken en el contexto global
    (window as any).getToken = authService.getToken.bind(this);
  }

  public getToken () {
    return this.authService.getToken();
  }


  ngOnInit() {
    this.authService.roles$.subscribe((roles) => {
      this.roles = roles;

      this.rolesDescription = '';
      //  console.log('Roles (ngOnInit):', this.roles);

      for (const role of this.roles) {
        this.rolesDescription = this.rolesDescription + role;
      }

      this.rolesDescription = this.rolesDescription.replace('ROLE_ADMINISTRACION', 'Administrador')
      .replace('ROLE_AGENCIA', 'Agencia')
      .replace('ROLE_USER', 'Usuario')
      .replace('ROLE_IT', 'IT');


      this.isUserLoggedIn = this.authService.isAuthenticated(); //roles.length > 0; // Actualiza el estado de login basado en roles

      this.initializeMenuItems();

      // Extrae el nombre del usuario del token
      const token = localStorage.getItem('token');
      if (token) {
        const payload = JSON.parse(atob(token.split('.')[1]));
        this.userName = payload['fullName'];
      } else {
        console.log('No token found!!!');
      }

      this.cdr.detectChanges(); // Forzar la detección de cambios para actualizar la vista
    });
  }

  initializeMenuItems() {
    this.items = [
      {
        label: 'Inicio',
        icon: 'pi pi-star-fill',
        routerLink: ['/home'],
        visible: true,
      },
      {
        label: 'Tablas de referencia',
        icon: 'pi pi-fw pi-list',
        visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION']),
        items: [
          {
            label: 'Agencias',
            icon: 'pi pi-fw pi-building',
            routerLink: ['/agencias'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION']),
          },
          {
            label: 'Catálogo',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/catalogo'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION']),
          },
          // {
          //   label: 'Servicios',
          //   icon: 'pi pi-fw pi-microchip',
          //   routerLink: ['/servicios'],
          //   visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION']),
          // },
          {
            label: 'Tipos de unidad',
            icon: 'pi pi-fw pi-microchip',
            routerLink: ['/tipos-unidad'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION']),
          },
          {
            label: 'Acuerdos',
            icon: 'pi pi-fw pi-microchip',
            routerLink: ['/acuerdos'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION']),
          },
          {
            label: 'Proveedores',
            icon: 'pi pi-fw pi-microchip',
            routerLink: ['/proveedores'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION']),
          },
          {
            label: 'Servicios de garantía',
            icon: 'pi pi-fw pi-microchip',
            routerLink: ['/servicios-garantia'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION']),
          },
          {
            label: 'Catalogo de Tareas',
            icon: 'pi pi-fw pi-microchip',
            routerLink: ['/catalogo-tareas'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION']),
          },
          {
            label: 'Tareas - Servicios',
            icon: 'pi pi-fw pi-microchip',
            routerLink: ['/tareas-servicios'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION']),
          },
        ],
      },
      {
        label: 'Unidades',
        icon: 'pi pi-fw pi-car',
        visible: this.authService.hasAnyRole(['ROLE_AGENCIA', 'ROLE_ADMINISTRACION']),
        items: [
          {
            label: 'Maestro de Unidades',
            icon: 'pi pi-fw pi-table',
            routerLink: ['/maestro-unidades'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION', 'ROLE_AGENCIA']),
          },
          {
            label: 'Maestro de Unidades Vendidas',
            icon: 'pi pi-fw pi-shopping-cart',
            routerLink: ['/maestro-unidades-vendidas'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION', 'ROLE_AGENCIA']),
          },
          {
            label: 'Historial de Servicio',
            icon: 'pi pi-calendar',
            routerLink: ['/historial-servicio'],
            visible: this.authService.hasAnyRole(['ROLE_ADMINISTRACION', 'ROLE_AGENCIA']),
          },
        ],
      },
    ];
  }

  logout() {
    this.authService.logout();
  }
}
