<p-toast position="top-center"></p-toast>
<p-confirmDialog></p-confirmDialog>

    <p-card class="" [style]="{ 'margin-top': '0px', 'padding': '0px', 'margin-right': '16px', 'margin-left': '16px', 'margin-bottom': '8px', 'background-color': 'transparent !important' }">
      <div class="flex gap-4 items-center">
        <p-autoComplete [(ngModel)]="dominioSearch" [suggestions]="dominiosCargados"
        [showClear]="true"
        [minLength]="1" placeholder="Buscar por dominio"  class="p-component w-1/2 mb-4 rounded-lg"
          (completeMethod)="searchDominioIncremental($event)"
          (onSelect)="searchHistorialServicioDominio($event)" [dropdown]="true"  [style]="{ 'width': '100%' }"
          >
        </p-autoComplete>

        <button pButton icon="pi pi-search" label="Buscar" (click)="searchHistorialServicioDominio(dominioSearch)" class="p-button-primary mb-4"></button>
      </div>

      <!-- Fila de botones: Nuevo, Eliminar, Editar -->
      <div class="flex gap-4 items-center">
        <button pButton icon="pi pi-plus" label="Nuevo" (click)="nuevoRegistro()" class="p-button-success"></button>
        <button pButton icon="pi pi-trash" label="Eliminar" (click)="deleteHistorialServicio()" class="p-button-danger" [disabled]="!selectedHistorialServicio"></button>
        <button pButton icon="pi pi-pencil" label="Editar" (click)="editarHistorialServicio()" class="p-button-warning" [disabled]="!selectedHistorialServicio"></button>
        <button pButton icon="pi pi-save" type="submit" class="p-2 btn btn-danger" [disabled]="!isEditing && !isCreating">
          Guardar
        </button>
        <button pButton icon="pi pi-times" (click)="cancelEdit()" class="p-2 p-button-primary btn btn-danger"
          [disabled]="!isEditing && !isCreating">
          Cancelar
        </button>
      <!-- añade un boton que al hacer click haga un this.router.navigate(['/maestro-unidades-vendidas', vin]) pasandole el vin como parametro;   -->
        <button pButton icon="pi pi-eye" label="Ir a unidad vendida" (click)="callUnidadVendida()" class="p-button-primary"
        [disabled]="!selectedHistorialServicio"></button>

        <!-- Mensaje de error para la eliminación de una Unidad Vendida -->
        <div *ngIf="deleteError" class="text-red-600">
          <small>{{ deleteError }}</small>
        </div>

        <!-- Mensaje de éxito para la eliminación de una Unidad Vendida -->
        <div *ngIf="deleteSuccess" class="text-green-600">
          <small>{{ deleteSuccess }}</small>
        </div>
      </div>
    </p-card>


<!-- Formulario para crear o editar un Historial de Servicio -->
<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <form [formGroup]="historialServicioForm" (ngSubmit)="isCreating ? createHistorialServicio() : updateHistorialServicio()">
    <div class="mx-auto rounded-3xl w-full">
      <h1 class="text-4xl font-semibold mb-4">Historial de Servicio</h1>
      <div class="rounded p-0 mt-0 mb-8">
        <h2 class="text-2xl font-semibold mb-2">Datos identificatorios</h2>
          <div class="grid grid-cols-5 gap-4 mb-6">
            <div class="p-field">
              <label for="vin" class="block text-white font-semibold">VIN *</label>
              <input id="vin"    [value]="selectedUnidadVendida?.vin"   pInputText class="p-element p-component w-full p-2 rounded-lg" [disabled]="true">
            </div>
            <div class="p-field">
              <label for="nroMotor" class="block text-white font-semibold">Número de Motor *</label>
              <!-- formControlName="nroMotor" -->
              <input id="nroMotor"   [value]="selectedUnidadVendida?.nroMotor"   pInputText class="p-element p-component w-full p-2 rounded-lg" [disabled]="true" />
            </div>
            <div class="p-field">
              <label for="tipoUnidad" class="block text-white font-semibold">Tipo de Unidad</label>
              <!-- formControlName="tipoUnidad" -->
              <input id="tipoUnidad"  [value]="selectedUnidadVendida?.tipoUnidad"   pInputText class=" p-element p-component w-full p-2 border rounded-lg" [disabled]="true" />
            </div>
            <div class="p-field">
              <label for="idUnidad" class="block text-white font-semibold">ID Unidad</label>
              <!-- formControlName="idUnidad" -->
              <input id="idUnidad"   [value]="selectedUnidadVendida?.idUnidad"   pInputText class=" p-element p-component w-full p-2 border rounded-lg" [disabled]="true" />
            </div>
            <div class="p-field">
              <label for="fechaGarantiaDate" class="block text-white font-semibold">Fecha de Garantía</label>

              <p-calendar id="fechaGarantiaDate" formControlName="fechaGarantiaDate"
              placeholder="dd/mm/yy" [dateFormat]="'dd/mm/yy'"
              inputStyleClass="w-full p-2 border rounded-lg"
              [style]="{ minWidth: '50px', width: '100%' }"
              [showIcon]="true"
              appendTo="body"
              [readonlyInput]="false"></p-calendar>
            </div>


          <!-- <div class="p-field">
            <label for="dominio" class="block text-white font-semibold">Dominio *</label>
            <input id="dominio" formControlName="dominio" pInputText class="p-inputtext w-full p-2 border rounded-lg" />
          </div> -->
        </div>
      </div>

      <div class="border border-gray-300 rounded p-2 mb-8">
        <h2 class="text-2xl font-semibold mb-4">Detalles</h2>
        <div class="grid grid-cols-4 gap-4 mb-6">
          <div class="p-field">
            <label for="fechaServicioDate" class="block text-white font-semibold">Fecha de Servicio *</label>
            <p-calendar placeholder="dd/mm/yy" showIcon="true" (onSelect)="assignGarantia()"  [dateFormat]="'dd/mm/yy'"  id="fechaServicioDate" [readonlyInput]="!isEditing && !isCreating" formControlName="fechaServicioDate" [style]="{ minWidth: '50px', width: '100%' }" inputStyleClass="w-full p-2 border rounded-lg" />
            <div *ngIf="historialServicioForm.get('fechaServicioDate')?.invalid && historialServicioForm.get('fechaServicioDate')?.touched">
              <small class="text-red-600" *ngIf="historialServicioForm.get('fechaServicioDate')?.errors?.['required']">
                La Fecha de Servicio es requerida
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="idAgencia" class="block text-white font-semibold">ID Agencia *</label>
            <!-- <input id="idAgencia" formControlName="idAgencia" pInputText class="w-full p-2 border rounded-lg" /> -->

            <p-dropdown id="idAgencia" formControlName="idAgencia"
            class="w-full p-dropdown-sm p-2"
            [ngClass]="{'p-invalid': historialServicioForm.get('idAgencia')?.invalid && (historialServicioForm.get('idAgencia')?.dirty || historialServicioForm.get('idAgencia')?.touched)}"
            [showClear]="true"
            [filter]="true"
            filterPlaceholder="Buscar Agencia"
            [options]="agencias" autoWidth="false"
            [style]="{ width: '100%',  height: '2.6rem' }"></p-dropdown>
            <div *ngIf="historialServicioForm.get('idAgencia')?.invalid && historialServicioForm.get('idAgencia')?.touched">
              <small class="text-red-600">
                El ID Agencia es requerido
              </small>
            </div>


            <div *ngIf="historialServicioForm.get('idAgencia')?.invalid && historialServicioForm.get('idAgencia')?.touched">
              <small class="text-red-600" *ngIf="historialServicioForm.get('idAgencia')?.errors?.['required']">
                El ID Agencia es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="kmIngreso" class="block text-white font-semibold">Kilómetros de Ingreso *</label>
            <input id="kmIngreso" formControlName="kmIngreso" (change)="assignGarantia()"  pInputText class="p-element p-component w-full p-2 border rounded-lg" />
            <div *ngIf="historialServicioForm.get('kmIngreso')?.invalid && historialServicioForm.get('kmIngreso')?.touched">
              <small class="text-red-600" *ngIf="historialServicioForm.get('kmIngreso')?.errors?.['required']">
                Los Kilómetros de Ingreso son requeridos
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="garantia" class="block text-white font-semibold">Garantía *</label>
            <p-dropdown
            [style]="{ height: '2.6rem', width: '100%' }"
            id="garantia"
            formControlName="garantia"
            [options]="garantiaOptions"
            filterPlaceholder="¿En garantía?"
            [disabled]="true"
            [ngClass]="{'p-invalid': historialServicioForm.get('garantia')?.invalid && (historialServicioForm.get('garantia')?.dirty || historialServicioForm.get('garantia')?.touched)}"
            autoWidth="false"
            class="w-full p-dropdown-sm p-2"
            >
            </p-dropdown>


            <div *ngIf="historialServicioForm.get('garantia')?.invalid && historialServicioForm.get('garantia')?.touched">
              <small class="text-red-600" *ngIf="historialServicioForm.get('garantia')?.errors?.['required']">
                La Garantía es requerida
              </small>
            </div>
          </div>

          <div class="p-field col-span-2">
            <label for="comentarios" class="block text-white font-semibold ">Comentarios</label>
            <textarea id="comentarios" formControlName="comentarios" pInputTextarea class="p-element p-component w-full p-2 border rounded-lg"></textarea>
          </div>
          <div class="p-field col-span-4">
            <div class="flex gap-4 mb-4">
              <div (click)="selectCard('servicio')"
                  [style]="{ 'background-color': selectedOption === 'servicio' ? '#2563EB' : 'transparent' }"
                  [ngClass]="{'border-blue-500': selectedOption === 'servicio', 'opacity-50': selectedOption === 'tarea'}"
                   class="border p-4 rounded-lg cursor-pointer transition duration-300">
                <h3 class="text-white font-semibold text-center">Seleccionar Servicio</h3>
              </div>
              <div (click)="selectCard('tarea')"
              [style]="{ 'background-color': selectedOption === 'tarea' ? '#2563EB' : 'transparent' }"
              [ngClass]="{'border-blue-500': selectedOption === 'tarea', 'opacity-50': selectedOption === 'servicio'}"
                   class="border p-4 rounded-lg cursor-pointer transition duration-300">
                <h3 class="text-white font-semibold text-center">Seleccionar Tarea</h3>
              </div>
            </div>
            <div *ngIf="selectedOption === 'servicio'" class="col-span-2">
              <label for="idServicio" class="block text-white font-semibold">ID Servicio *</label>
              <p-dropdown id="idServicio" formControlName="idServicio"
                class="w-full p-dropdown-sm p-2"
                autowidth="false"
                [style]="{ 'width': '50%' }"
                [ngClass]="{'p-invalid': historialServicioForm.get('idServicio')?.invalid && (historialServicioForm.get('idServicio')?.dirty || historialServicioForm.get('idServicio')?.touched)}"
                [showClear]="true"
                [filter]="true"
                filterPlaceholder="Buscar Servicio"
                [options]="serviocsGarantiaOptions">
              </p-dropdown>
            </div>
            <div *ngIf="selectedOption === 'tarea'">
              <label for="idTarea" class="block text-white font-semibold">ID Tarea *</label>
              <p-dropdown id="idTarea" formControlName="idTarea"
                class="w-full p-dropdown-sm p-2"
                [ngClass]="{'p-invalid': historialServicioForm.get('idTarea')?.invalid && (historialServicioForm.get('idTarea')?.dirty || historialServicioForm.get('idTarea')?.touched)}"
                [showClear]="true"
                [filter]="true"
                [style]="{ 'width': '50%' }"
                autowidth="false"
                filterPlaceholder="Buscar Tarea"
                [options]="catalogoTareasOptions">
              </p-dropdown>
            </div>

          </div>
        </div>
        </div>
      </div>

      <div class="flex gap-2 mb-6">
        <button pButton type="submit" class="p-2 btn btn-danger" [disabled]="!isEditing && !isCreating">Guardar</button>
        <button pButton (click)="cancelEdit()" class="p-2 p-button-primary btn btn-danger" [disabled]="!isEditing && !isCreating">Cancelar</button>
      </div>


      <div class="grid grid-cols-4 gap-4 border border-gray-300 p-2 mb-8 rounded relative">
        <!-- Spinner overlay -->
        <div *ngIf="isUploading"
             class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <p-progressSpinner
            styleClass="w-12 h-12"
            strokeWidth="4"
            fill="var(--surface-ground)"
            animationDuration=".5s">
          </p-progressSpinner>
        </div>

        <div class="p-field col-span-4" [class.pointer-events-none]="isUploading">
          <div>
            <h2 class="text-2xl font-semibold mb-8">Documentos del Historial de Servicio</h2>
            <div class="p-4 rounded-lg mb-4">
              <div class="custom-file-upload">
                <input
                  #fileInput
                  id="fileInput"
                  [disabled]="!selectedHistorialServicio || (!isCreating && !isEditing) || isUploading"
                  type="file"
                  class="hidden"
                  (change)="asyncCallSubirDocumento($event)"
                />
                <label for="fileInput"
                  class="cursor-pointer bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg inline-block"
                  [class.opacity-50]="!selectedHistorialServicio || (!isCreating && !isEditing) || isUploading">
                  <i class="pi pi-upload mr-2"></i>
                  Seleccionar archivo
                </label>
              </div>
          </div>
            <ul class="space-y-2">
              <li *ngFor="let doc of documentos" class="bg-gray-800 rounded-lg p-3 hover:bg-gray-700 transition-colors">
                <div class="flex items-center w-full">
                  <!-- Ícono del archivo basado en el tipo -->
                  <div class="flex-shrink-0 mr-3">
                    <i [class]="getFileIconClass(doc.nombre)" class="text-2xl"></i>
                  </div>

                  <!-- Información del archivo -->
                  <div class="flex-grow">
                    <span class="text-white block">{{ doc.nombre || 'Sin nombre' }}</span>
                    <span class="text-gray-400 text-sm">{{ doc.tamanio || 0 }} KB</span>
                    <!-- {{ doc.tipo || 'Sin tipo' }} -  -->
                  </div>

                  <!-- Botones de acción -->
                  <div class="flex gap-2 ml-4">
                    <button pButton
                            type="button"
                            icon="pi pi-eye"
                            class="p-button-rounded p-button-text"
                            pTooltip="Ver documento"
                            [disabled]="isUploading"
                            (click)="verDocumento(doc.id)">
                    </button>
                    <button pButton
                            type="button"
                            icon="pi pi-download"
                            class="p-button-rounded p-button-text"
                            pTooltip="Descargar documento"
                            [disabled]="isUploading"
                            (click)="descargarDocumento(doc.id)">
                    </button>
                    <button pButton
                            type="button"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger p-button-text"
                            pTooltip="Eliminar documento"
                            [disabled]="isUploading"
                            (click)="eliminarDocumento(doc.id)">
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>


      <div class="flex gap-4 justify-center col-span-4">
        <p-paginator  [rows]="rows" [totalRecords]="historialServicios.length" class="w-full"   (onPageChange)="paginate($event)"></p-paginator>
      </div>
  </form>
</p-card>

<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <!-- Tabla de historial de servicio -->
  <p-table [value]="historialServicios" styleClass="p-datatable-striped" [scrollable]="true">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
        Lista de Historial de Servicio
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Dominio</th>
        <th>Fecha de Servicio</th>
        <th>ID Agencia</th>
        <th>Kilómetros de Ingreso</th>
        <th>Consultar</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-historialServicio>
      <tr>
        <td>{{ historialServicio.dominio }}</td>
        <td>{{ historialServicio.fechaServicio | date:'dd/MM/yyyy' }}</td>
        <td>{{ historialServicio.idAgencia }}</td>
        <td>{{ historialServicio.kmIngreso }}</td>
        <td>
          <button pButton icon="pi pi-search" (click)="consultarHistorialServicioByFecha(historialServicio.fechaServicio)" class="p-button-primary"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
