import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { HistorialServicio } from '../models/historial-servicio.model';
import { FechaService } from './fecha.service';


@Injectable({
  providedIn: 'root',
})
export class HistorialServicioService {
  private baseUrl = `${environment.baseUrl}/api/historial-servicio`;

  constructor(private http: HttpClient, private fechaService: FechaService) {}

  /**
   * Obtener un historial de servicio por dominio
   * @param dominio Dominio del historial de servicio
   * @returns Observable con los datos del historial de servicio
   */
  getHistorialesServicioByDominio(dominio: string): Observable<HistorialServicio[]> {
    return this.http.get<HistorialServicio[]>(`${this.baseUrl}/dominio/${dominio}`).pipe(
      map((historiales) =>
        historiales.map((historial) => ({
          ...historial,
          //importante se usa esta funcion con registros generados vía sql
          fechaServicioDate: this.fechaService.fromBackendStringDate(historial.fechaServicio), // Convertir a Date
        }))
      )
    );
  }

  /**
   * Crear un nuevo historial de servicio
   * @param historialServicio Datos del historial de servicio
   * @returns Observable con el historial de servicio creado
   */
  createHistorialServicio(historialServicio: HistorialServicio): Observable<HistorialServicio> {
    const historialServicioBackend = {
      ...historialServicio,
      fechaServicio: this.fechaService.toBackendDate(historialServicio.fechaServicioDate!), // Convertir Date a [number, number, number]
    };

    return this.http.post<HistorialServicio>(this.baseUrl, historialServicioBackend);
  }

  /**
   * Actualizar un historial de servicio existente
   * @param dominio Dominio del historial de servicio
   * @param historialServicio Datos actualizados del historial de servicio
   * @returns Observable con el historial de servicio actualizado
   */
  updateHistorialServicio(
    dominio: string,
    historialServicio: HistorialServicio
  ): Observable<HistorialServicio> {
    const historialServicioBackend = {
      ...historialServicio,
      fechaServicio: this.fechaService.toBackendDate(historialServicio.fechaServicioDate!), // Convertir Date a [number, number, number]
    };

    return this.http.put<HistorialServicio>(
      `${this.baseUrl}/${dominio}`,
      historialServicioBackend
    ).pipe(
      map((historial) => ({
        ...historial,
        fechaServicioDate: this.fechaService.fromBackendDate(historial.fechaServicio), // Convertir a Date
      }))
    );
  }

  /**
   * Eliminar un historial de servicio
   * @param dominio Dominio del historial de servicio
   * @returns Observable<void> indicando éxito
   */
  deleteHistorialServicio(dominio: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/dominio/${dominio}`);
  }

  /**
   * Buscar historial de servicios por un término
   * @param termino Término de búsqueda
   * @returns Observable con los historiales de servicio que coincidan con el término
   */
  searchHistorialServicios(termino: string): Observable<HistorialServicio[]> {
    return this.http.get<HistorialServicio[]>(
      `${this.baseUrl}/buscar?termino=${encodeURIComponent(termino)}`
    ).pipe(
      map((historiales) =>
        historiales.map((historial) => ({
          ...historial,
          fechaServicioDate: this.fechaService.fromBackendDate(historial.fechaServicio), // Convertir a Date
        }))
      )
    );
  }
}
