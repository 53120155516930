// error-handler.service.ts
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

interface ValidationError {
  status: number;
  message: string;
  details: string;
  validationErrors: Record<string, string> | null;
}

@Injectable()
export class ErrorHandlerService {
  handleError(
    error: HttpErrorResponse,
    messageService: MessageService, // Ahora recibimos el MessageService como parámetro
    customConfig?: {
      defaultMessage?: string;
      severity?: 'error' | 'warn' | 'info';
    }
  ): void {
    const config = {
      defaultMessage: 'Ha ocurrido un error inesperado',
      severity: 'error' as const,
      ...customConfig
    };

    // console.log('Processing error in service:', error);

    if (!error.error) {
      if (config.defaultMessage) {
        this.showMessage(messageService, config.defaultMessage, config.severity);
      }
      else {
        this.showMessage(messageService, JSON.stringify(error), config.severity);
        console.error('Unhandled error', error);
      }
      return;
    }

    const errorResponse = error.error as ValidationError;

    if (errorResponse.validationErrors) {
      this.handleValidationErrors(messageService, errorResponse.validationErrors);
      return;
    }

    if (errorResponse.details) {
      this.showMessage(messageService, errorResponse.details, config.severity);
      return;
    }

    if (errorResponse.message) {
      this.showMessage(messageService, errorResponse.message, config.severity);
      return;
    }

    if (config.defaultMessage) {
      this.showMessage(messageService, config.defaultMessage, config.severity);
    }
    else {
      this.showMessage(messageService, JSON.stringify(error), config.severity);
    }
  }

  private handleValidationErrors(
    messageService: MessageService,
    validationErrors: Record<string, string>
  ): void {
    Object.entries(validationErrors).forEach(([field, message]) => {
      this.showMessage(messageService, message, 'error', `Error en ${field}`);
    });
  }

  private showMessage(
    messageService: MessageService,
    detail: string,
    severity: 'error' | 'warn' | 'info',
    summary: string = 'Error'
  ): void {
    console.log('Showing message from service:', { severity, summary, detail });

    messageService.add({
      severity,
      summary,
      detail,
      life: 5000
    });
  }
}
