// agencias.component.ts
import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AgenciasService } from '../../../services/agencias.service';
import { Agencia } from '../../../models/agencias.model';
import { BaseComponent } from '../../../base/base.component';

@Component({
  selector: 'app-agencias',
  templateUrl: './agencias.component.html',
  providers: [MessageService, ConfirmationService]
})
export class AgenciasComponent extends BaseComponent implements OnInit {
  agencias: Agencia[] = [];
  agencia: Agencia = this.getEmptyAgencia();
  dialogVisible = false;
  loading = false;
  globalFilter: string = '';
  isNew: boolean = true;

  constructor(
    private agenciasService: AgenciasService,
    private confirmationService: ConfirmationService
  ) { super(); }

  ngOnInit() {
    this.loadAgencias();
  }

  getEmptyAgencia(): Agencia {
    return {
      idAgencia: 0,
      nombre: '',
      cuitCuil: '',
      movil: null,
      correo: null,
      calle: null,
      numero: null,
      provincia: null,
      localidad: null,
      codPostal: null,
      ponderacion: null,
      comentarios: null
    };
  }

  loadAgencias() {
    this.loading = true;
    this.agenciasService.getAll().subscribe({
      next: (data) => {
        this.agencias = data;
        this.loading = false;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al cargar las agencias'
        });
        this.loading = false;
      }
    });
  }

  showDialog(agencia?: Agencia) {
    if (agencia) {
      this.agencia = { ...agencia };
      this.isNew = false;
    } else {
      this.agencia = this.getEmptyAgencia();
      this.isNew = true;
    }
    this.dialogVisible = true;
  }

  saveAgencia() {
    if (this.isNew) {
      this.agenciasService.create(this.agencia).subscribe({
        next: (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Agencia creada correctamente'
          });
          this.loadAgencias();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    } else {
      this.agenciasService.update(this.agencia.idAgencia, this.agencia).subscribe({
        next: (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Agencia actualizada correctamente'
          });
          this.loadAgencias();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    }
  }

  deleteAgencia(agencia: Agencia) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar esta agencia?',
      accept: () => {
        this.agenciasService.delete(agencia.idAgencia).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Agencia eliminada correctamente'
            });
            this.loadAgencias();
          },
          error: (error) => {
            this.handleError(error);
          }
        });
      }
    });
  }
}
