import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Acuerdo } from '../models/acuerdo.model';

@Injectable({
  providedIn: 'root'
})
export class AcuerdoService {
  private baseUrl: string = environment.baseUrl + '/api/acuerdos';

  constructor(private http: HttpClient) {}

  getAll(): Observable<Acuerdo[]> {
    return this.http.get<Acuerdo[]>(this.baseUrl);
  }

  getById(tipoAcuerdo: string): Observable<Acuerdo> {
    return this.http.get<Acuerdo>(`${this.baseUrl}/${tipoAcuerdo}`);
  }

  create(acuerdo: Acuerdo): Observable<Acuerdo> {
    return this.http.post<Acuerdo>(this.baseUrl, acuerdo);
  }

  update(tipoAcuerdo: string, acuerdo: Acuerdo): Observable<Acuerdo> {
    return this.http.put<Acuerdo>(`${this.baseUrl}/${tipoAcuerdo}`, acuerdo);
  }

  delete(tipoAcuerdo: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${tipoAcuerdo}`);
  }
}
