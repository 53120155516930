import { DocumentoHistorialServicioService } from './../../services/documento-historial-servicio.service';
import { CatalogoTareasService } from './../../services/catalogo-tareas.service';
import { HistorialServicio } from './../../models/historial-servicio.model';
import { HistorialServicioService } from './../../services/historial-servicio.service';
import { TitularService } from './../../services/titular.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UnidadVendida } from '../../models/unidades-vendidas.model';
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { UnidadService } from '../../services/unidad-maestro.service';
import { UnidadesVendidasService } from '../../services/unidades-vendidas.service';
import { AgenciasService } from '../../services/agencias.service';
import { Agencia } from '../../models/agencias.model';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ServicioGarantia } from '../../models/servicios-garantia.model';
import { ServiciosGarantiaService } from '../../services/servicios-garantia.service';
import { CatalogoTareas } from '../../models/catalogo-tareas.model';
import { CatalogoService } from '../../services/catalogo.service';
import { DocumentoHistorialServicio } from '../../models/documento-historial-servicio.model';
import { BaseComponent } from '../../base/base.component';


@Component({
  selector: 'app-historial-servicio',
  templateUrl: './historial-servicio.component.html',
  styleUrls: ['./historial-servicio.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class HistorialServicioComponent extends BaseComponent implements OnInit {

  historialServicios: HistorialServicio[] = [];
  selectedHistorialServicio: HistorialServicio | null = null;
  paginatedHistorialServicios: HistorialServicio[] = [];
  isUploading: boolean = false;

  historialServicioForm: FormGroup;
  isCreating: boolean = false;
  deleteError: string | null = null;
  deleteSuccess: string | null = null;
  dominioSearch: string = '';
  isEditing: boolean = false;
  isCreateMode: boolean = false;
  selectedUnidadVendida: UnidadVendida | null = null;
  dominiosCargados: string[] = [];
  unidadesVendidas: UnidadVendida[] = [];
  agencias: { label: string, value: any }[] = [];
  garantiaOptions: { label: string, value: any }[] = [{ label: 'Sí', value: true }, { label: 'No', value: false }];
  serviciosGarantia: ServicioGarantia[] = [];
  serviocsGarantiaOptions: { label: string, value: any }[] = [];
  catalogoTareas: CatalogoTareas[] = [];
  catalogoTareasOptions: { label: string, value: any }[] = [];
  selectedOption: string = ''; // 'servicio' o 'tarea'
  archivo: File | null = null;
  documentos: DocumentoHistorialServicio[] = [];

  rows: number = 1; // Number of rows per page
  first: number = 0; // Index of the first row


  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private catalogoTareasService: CatalogoTareasService,
    private route: ActivatedRoute,
    private router: Router,
    private unidadesVendidasService: UnidadesVendidasService,
    private historialServicioService: HistorialServicioService,
    private agenciasService: AgenciasService,
    private serviciosGarantiaService: ServiciosGarantiaService,
    private unidadService: UnidadService,
    private documentoService: DocumentoHistorialServicioService,
    private titularService: TitularService,
  ) {
    super();
    this.historialServicioForm = this.fb.group({
      dominio: new FormControl({ value: '', disabled: true }, Validators.required),
      fechaServicioDate: new FormControl({ value: '', disabled: true }, Validators.required),
      idAgencia: new FormControl({ value: '', disabled: true }, Validators.required),
      kmIngreso: new FormControl({ value: '', disabled: true }, Validators.required),
      garantia: new FormControl({ value: '', disabled: false }, Validators.required),
      idServicio: new FormControl({ value: '', disabled: true }),
      idTarea: new FormControl({ value: '', disabled: true }),
      comentarios: new FormControl({ value: '', disabled: true }),
      tipoUnidad: new FormControl({ value: '', disabled: true }, Validators.required),
      idUnidad: new FormControl({ value: '', disabled: true }, Validators.required),
      fechaGarantiaDate: new FormControl({ value: '', disabled: true }),
    });
  }



  obtenerDocumentos(): void {
    this.documentoService.obtenerDocumentos(this.dominioSearch, this.selectedHistorialServicio!.fechaServicio)
      .subscribe({
        next: (data) => this.documentos = data,
        error: (err) => console.error('Error al obtener documentos', err)
      });
  }


  getFileIconClass(name: string | undefined): string {
    const tipoLower = (name || '').toLowerCase();

    if (tipoLower.includes('pdf')) {
      return 'pi pi-file-pdf text-red-400';
    }
    if (tipoLower.includes('excel') || tipoLower.includes('xlsx')) {
      return 'pi pi-file-excel text-green-400';
    }
    if (tipoLower.includes('doc')) {
      return 'pi pi-file-word text-blue-400';
    }
    if (['jpg', 'jpeg', 'png', 'heic'].some(ext => tipoLower.includes(ext))) {
      return 'pi pi-images text-pink-400';
    }
    if (tipoLower.includes('ppt')) {
      return 'pi pi-file-powerpoint text-orange-400';
    }
    if (tipoLower.includes('zip')) {
      return 'pi pi-file-zip text-purple-400';
    }

    return 'pi pi-file text-gray-400';
  }

  async asyncCallSubirDocumento(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    try {
      this.isUploading = true;
      const archivo = event.target.files[0];

      // Convertir la llamada al servicio en una Promesa
      await new Promise((resolve, reject) => {
        this.documentoService.subirDocumento(this.dominioSearch, this.selectedHistorialServicio!.fechaServicio, archivo)
          .subscribe({
            next: (data) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Éxito',
                detail: 'Documento subido con éxito'
              });
              this.obtenerDocumentos();
              resolve(data);
            },
            error: (err) => {
              this.handleError(err);
              reject(err);
            }
          });
      });
    } catch (error) {
      console.error('Error al subir el documento:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error al subir el documento'
      });
    } finally {
      this.isUploading = false;
      // Limpiar el input file
      const fileInput = event.target as HTMLInputElement;
      fileInput.value = '';
    }
  }

  subirDocumento(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      this.archivo = target.files[0];
      this.documentoService.subirDocumento(this.dominioSearch, this.selectedHistorialServicio!.fechaServicio, this.archivo)
        .subscribe({
          next: (data) => {
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Documento subido con éxito' });
            this.obtenerDocumentos(); // Recargar lista
          },
          error: (err) => {
            this.handleError(err);
          }

        });
    }
  }

  descargarDocumento(idDocumento: number): void {
    this.documentoService.descargarDocumento(this.dominioSearch, this.selectedHistorialServicio!.fechaServicio, idDocumento)
      .subscribe({
        next: (blob) => {
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = `documento_${idDocumento}.pdf`; // Asigna un nombre al archivo descargado
          a.click();
          URL.revokeObjectURL(objectUrl); // Limpia el objeto URL creado
        },
        error: (err) => console.error('Error al descargar el documento', err)
      });
  }

  verDocumento(idDocumento: number): void {
    this.documentoService.descargarDocumento(this.dominioSearch, this.selectedHistorialServicio!.fechaServicio, idDocumento)
      .subscribe({
        next: (blob) => {
          // Crear una URL temporal para el archivo descargado
          const objectUrl = URL.createObjectURL(blob);

          // Abrir el archivo en una nueva pestaña
          window.open(objectUrl, '_blank');

          // Liberar la URL después de que el archivo se haya abierto
          URL.revokeObjectURL(objectUrl);
        },
        error: (err) => console.error('Error al visualizar el documento', err)
      });
  }

  eliminarDocumento(idDocumento: number): void {
    this.documentoService.eliminarDocumento(this.dominioSearch, this.selectedHistorialServicio!.fechaServicio, idDocumento)
      .subscribe({
        next: () => {
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Documento eliminado exitosamente' });
          this.obtenerDocumentos(); // Refresca la lista
        },
        error: (err) => console.error('Error al eliminar el documento', err)
      });
  }

  selectCard(option: string) {
    this.selectedOption = option;
    this.historialServicioForm.get('idServicio')?.reset();
    this.historialServicioForm.get('idTarea')?.reset();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

      this.loadUnidadesVendidas();
      this.loadAgencias(); // Cargar agencias al inicializar el componente
      this.loadServiciosGarantia();
      this.loadCatalogoTareas();

      this.isCreateMode = this.route.snapshot.url.some(segment => segment.path === 'create');
      if (params['dominio']) {
        this.dominioSearch = params['dominio'] || '';
        // console.log(this.dominioSearch);
      }

      if (this.dominioSearch) {
        this.searchHistorialServicioDominio(this.dominioSearch);
      }

      if (this.isCreateMode) {

        if (!params['dominio']) {
          console.error('error no hay dominio en la ruta');
          return;
        }

        if (!this.selectedHistorialServicio) {
          this.nuevoRegistro();
          this.searchUnidadVendidaAndLoadAttrs(this.dominioSearch);
        }
      }
    }
  );
  }

  paginate(event: any) {
    this.first = event.first;

    this.consultarHistorialServicioByFecha(this.historialServicios[this.first].fechaServicio);
  }

  loadAgencias(): void {
    this.agenciasService.getAll().subscribe({
      next: (data: Agencia[]) => {
        this.agencias = data.map(agencia => ({
          label: agencia.nombre,
          value: agencia.idAgencia
        }));
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar agencias' });
      }
    });
  }

  loadServiciosGarantia(): void {
    this.serviciosGarantiaService.getAllServiciosGarantia().subscribe({
      next: (data: ServicioGarantia[]) => {
        this.serviciosGarantia = data;
        this.serviocsGarantiaOptions = data.map(servicio => ({ label: servicio.descripcion, value: servicio.idServicio }));
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar servicios de garantía' });
      }
    });
  }

  loadCatalogoTareas(): void {
    this.catalogoTareasService.getAllCatalogoTareas().subscribe({
      next: (data: CatalogoTareas[]) => {
        this.catalogoTareas = data;
        this.catalogoTareasOptions = data.map(tarea => ({ label: tarea.descripcion, value: tarea.idTarea }));
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar catálogo de tareas' });
      }
    });
  }


  loadUnidadesVendidas(): void {
    this.unidadesVendidasService.getAllUnidadesVendidas().subscribe({
      next: (data: UnidadVendida[]) => {
        this.unidadesVendidas = data;
        this.dominiosCargados = this.unidadesVendidas.map(unidad => unidad.dominio);
      },
      error: (error) => {
        console.log('Error al cargar unidades vendidas:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades vendidas' });
      }
    });
  }


  searchDominioIncremental(event: AutoCompleteCompleteEvent): void {
    const query = event.query.toLowerCase();
    this.dominiosCargados = this.unidadesVendidas
      .filter(unidadVendida => unidadVendida.dominio.toLowerCase().startsWith(query))
      .map(unidadVendida => unidadVendida.dominio);
  }



  searchUnidadVendidaAndLoadAttrs(value: string | Event | AutoCompleteSelectEvent): void {
    let dominio: any;

    if (typeof value === 'string') {
      dominio = value;
    } else if (value instanceof Event) {
      dominio = (value.target as HTMLInputElement).value;
    } else if ('originalEvent' in value && 'value' in value) {
      dominio = value.value; // `value` viene del AutoCompleteSelectEvent
    }

    if (!dominio) {
      console.error('No se ha ingresado un dominio (searchUnidadVendidaAndLoadAttrs)');
      return;
    }

    // console.log('Buscando unidad con dominio:', dominio);

    this.unidadesVendidasService.getUnidadVendidaByDominio(dominio).subscribe(
      (UnidadVendida: UnidadVendida) => {
        if (UnidadVendida) {
          this.selectedUnidadVendida = UnidadVendida; // Acceder al primer elemento del array
          this.historialServicioForm.get('fechaGarantiaDate')?.setValue(UnidadVendida.fechaGarantiaDate);

        } else {
          console.warn('No se encontró ninguna unidad vendida.');
        }
      },
      (error) => {
        console.log('Error al buscar unidad vendida:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unidad vendida no encontrada' });
      }
    );

  }


//ok
  nuevoRegistro(): void {
    this.isCreating = true;
    this.historialServicioForm.reset();
    this.selectedHistorialServicio = null;
    this.isEditing = true;
    this.selectedUnidadVendida = null;
    this.enableForm();

    this.searchUnidadVendidaAndLoadAttrs(this.dominioSearch);
    this.deshabilitarExtraAttrs();

  }

  deshabilitarExtraAttrs() : void {
    this.historialServicioForm.get('garantia')?.updateValueAndValidity();
    this.historialServicioForm.get('garantia')?.disable();
    this.historialServicioForm.get('nroMotor')?.disable();
    this.historialServicioForm.get('nroMotor')?.updateValueAndValidity();
    this.historialServicioForm.get('tipoUnidad')?.disable();
    this.historialServicioForm.get('tipoUnidad')?.updateValueAndValidity();
    this.historialServicioForm.get('idUnidad')?.disable();
    this.historialServicioForm.get('idUnidad')?.updateValueAndValidity();
    this.historialServicioForm.get('fechaGarantiaDate')?.disable();
    this.historialServicioForm.get('fechaGarantiaDate')?.updateValueAndValidity();
  }


  assignGarantia(): void {

    this.historialServicioForm.get('garantia')?.setValue(false);

    //genera una nueva fecha a partir de this.selectedUnidadVendida!.fechaGarantiaDate! sumandole 6 meses
    let fechaGarantia = new Date(this.selectedUnidadVendida!.fechaGarantiaDate!);
    fechaGarantia.setMonth(fechaGarantia.getMonth() + 6);

    if (this.historialServicioForm.get('kmIngreso')?.value < 10000 &&
        this.historialServicioForm.get('fechaServicioDate')?.value < fechaGarantia) {
        this.historialServicioForm.get('garantia')?.setValue(true);
    }

    this.historialServicioForm.get('garantia')?.updateValueAndValidity();
  }

  // Implementada, falta probar
  searchHistorialServicioDominio(value: string | Event | AutoCompleteSelectEvent): void {
    let dominio: any;

    if (typeof value === 'string') {
      dominio = value;
    } else if (value instanceof Event) {
      dominio = (value.target as HTMLInputElement).value;
    } else if ('originalEvent' in value && 'value' in value) {
      dominio = value.value; // `value` viene del AutoCompleteSelectEvent
    }

    // this.messageService.add({ severity: 'success', summary: 'Debug', detail: 'vin=' + vin });
0
    this.historialServicioService.getHistorialesServicioByDominio(dominio).subscribe({
      next: (data: HistorialServicio[]) => {
        this.selectedHistorialServicio = data[0];
        this.historialServicios = data;

        // console.log('Historial de servicio:', this.historialServicios);

        // this.paginate({ first: this.first, rows: this.historialServicios.length });
        this.first = 0;
        this.rows = 1;
        this.paginatedHistorialServicios = this.historialServicios.slice(this.first, this.rows);
        // this.paginate({ first: this.first, rows: this.rows });
        this.historialServicioForm.patchValue(this.selectedHistorialServicio);

        // console.log('selectedHistorialServicio:', this.selectedHistorialServicio);
        // comentado a pedido de marce
        // this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Historial de servicio encontrado' });

        this.searchUnidadVendidaAndLoadAttrs(dominio);

        this.obtenerDocumentos();

        this.isEditing = false;
        this.isCreating = false;
        this.disableForm();

        // Lleva al usuario al inicio de la pagina
        window.scrollTo(0, 0);
      },
      error: (err) => {
        console.error('Error al obtener historial de servicio:', err);
        // Puedes agregar manejo de errores aquí, por ejemplo:
        //  this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No hay historial de servicio aún para esta unidad. Se creará uno:' });

        // this.nuevoRegistro();
        this.searchUnidadVendidaAndLoadAttrs(dominio);

        // carga el dominio del formulario con el dominio de busqueda
        this.historialServicioForm.get('dominio')?.setValue(dominio);
      },
      complete: () => {
        // console.log('Petición completada');
      }
    });
  }

  //ok
  isNotValidAndCompleteForm(): boolean {
    let faltanCampos=false;

    // Log de errores de cada control antes de guardar
    Object.keys(this.historialServicioForm.controls).forEach(key => {
      const controlErrors = this.historialServicioForm.get(key)?.errors;

      // console.log('Control:', key, 'Errores:', controlErrors, 'Valor:', this.historialServicioForm.get(key)?.value);

      if (controlErrors) {
        console.log(`Errores en el campo ${key}:`, controlErrors);

        //Marca el input como ng-dirty o algo similar
        this.historialServicioForm.get(key)?.markAsDirty();
        this.historialServicioForm.get(key)?.markAsTouched();

        faltanCampos=true;
      }
    });

    if(faltanCampos){
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Formulario inválido: Complete los campos obligatorios'});
    }
    return faltanCampos;
  }

  // Implementada, falta probar
  createHistorialServicio(): void {

    let creatingDominio = this.dominioSearch;
    this.historialServicioForm.get('dominio')?.setValue(creatingDominio);
    // console.log('Dominio:', creatingDominio);

    if (this.isNotValidAndCompleteForm()) {
      return;
    }

    if (this.historialServicioForm.valid) {

      console.log(this.historialServicioForm.get('idServicio')?.value);
      console.log(this.historialServicioForm.get('idTarea')?.value);

      if (this.historialServicioForm.get('idServicio')?.value && this.historialServicioForm.get('idTarea')?.value) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No puede seleccionar un servicio y una tarea al mismo tiempo' });
        return;
      }

      let historialServicio = this.historialServicioForm.getRawValue() as HistorialServicio;

      // // si los campos idServicio y idtarea ambos están vacíos, marcar el formulario como invalido
      if (historialServicio.idServicio == null && historialServicio.idTarea == null) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar un servicio o una tarea' });
        return;
      }

      historialServicio.dominio = creatingDominio;

      this.historialServicioService.createHistorialServicio(historialServicio).subscribe({

        next: () => {
          this.historialServicios.push(this.historialServicioForm.getRawValue() as HistorialServicio);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Historial de Servicio creado exitosamente' });

          // this.loadHistorialServicios();
          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();
          this.searchHistorialServicioDominio(creatingDominio);
        },
        error: (error: any) => {
          this.handleError(error, {
            defaultMessage: 'Error al crear historial de servicio',
            severity: 'error'
          });
        }
      });
    }
  }

  // Implementada, falta probar
  editarHistorialServicio(): void {
    if (this.selectedHistorialServicio) {
      this.isEditing = true;
      this.isCreating = false;
      this.enableForm();

      this.deshabilitarExtraAttrs();
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Seleccione una unidad vendida para editar' });
    }
  }

  callUnidadVendida(): void {
    if (this.selectedHistorialServicio) {
      this.router.navigate(['/maestro-unidades-vendidas/dominio/', this.selectedUnidadVendida!.dominio]);
    }
  }


  // Implementada, falta probar
  updateHistorialServicio(): void {
    if (this.isNotValidAndCompleteForm()) {
      return;
    }

    if (this.historialServicioForm.valid) {
      let historialServicio = this.historialServicioForm.getRawValue() as HistorialServicio;

      this.historialServicioService.createHistorialServicio(historialServicio).subscribe({
        next: (data: HistorialServicio) => {
          this.selectedHistorialServicio = data;
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Historial de Servicio actualizado exitosamente' });
          // this.loadHistorialServicios();
          this.searchHistorialServicioDominio(historialServicio.dominio);
          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();
        },
        error: (error: any) => {
          this.handleError(error);
        }
      });
    }

  }

  // Implementada, falta probar
  deleteHistorialServicio(): void {
    if (this.selectedHistorialServicio) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar este historial de servicio?',
        accept: () => {
          this.historialServicioService.deleteHistorialServicio(this.selectedHistorialServicio!.dominio).subscribe({
            next: () => {
              this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Historial de Servicio eliminado exitosamente' });
              this.searchHistorialServicioDominio(this.selectedHistorialServicio!.dominio);
              this.selectedHistorialServicio = null;
            },
            error: (error: any) => {
              this.handleError(error);
            }
          });
        }
      });
    }
  }

//ok
  cancelEdit(): void {
    this.isCreating = false;
    this.selectedHistorialServicio = null;
    this.selectedUnidadVendida = null;
    this.historialServicioForm.reset();
    this.isEditing = false;
    this.disableForm();
  }

//ok
  enableForm(): void {
    Object.keys(this.historialServicioForm.controls).forEach(controlName => {
      const control = this.historialServicioForm.get(controlName);
      control?.enable();
      control?.updateValueAndValidity(); // Asegúrate de actualizar la validez
    });
    Object.keys(this.historialServicioForm.controls).forEach(controlName => {
      this.historialServicioForm.get(controlName)?.enable();
    });
  }

  //ok
  disableForm(): void {
    Object.keys(this.historialServicioForm.controls).forEach(controlName => {
      this.historialServicioForm.get(controlName)?.disable();
    });
  }

//ok
  consultarHistorialServicio(historialServicio: HistorialServicio): void {
    this.selectedHistorialServicio = historialServicio;
    this.historialServicioForm.patchValue(historialServicio);
    this.dominioSearch = historialServicio.dominio;

    this.searchUnidadVendidaAndLoadAttrs(historialServicio.dominio);
  }

  consultarHistorialServicioByFecha(FechaService: any): void {

    // console.log('Fecha:', FechaService);
    // console.log('Fechas:', this.historialServicios.map(historialServicio => historialServicio.fechaServicio));

    this.selectedHistorialServicio = this.historialServicios.find(historialServicio =>
      FechaService === historialServicio.fechaServicio
    ) ?? null;

    if (this.selectedHistorialServicio) {
      this.historialServicioForm.patchValue(this.selectedHistorialServicio);
      this.obtenerDocumentos();

      Object.keys(this.historialServicioForm.controls).forEach(controlName => {
        const control = this.historialServicioForm.get(controlName);
        control?.updateValueAndValidity(); // Asegúrate de actualizar la validez
      });

      // console.log(this.selectedHistorialServicio);
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'No se encontró historial de servicio para la fecha proporcionada:' + FechaService });
    }

    // this.searchUnidadVendidaAndLoadAttrs(this.dominioSearch);
    window.scrollTo(0, 0);

  }

}
