// servicios-garantia.component.ts
import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ServiciosGarantiaService } from '../../services/servicios-garantia.service';
import { TiposUnidadService } from '../../services/tipos-unidad.service';
import { ServicioGarantia } from '../../models/servicios-garantia.model';
import { Catalogo } from '../../models/catalogo.model';
import { CatalogoService } from '../../services/catalogo.service';

interface DropdownOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-servicios-garantia',
  templateUrl: './servicios-garantia.component.html',
  providers: [MessageService, ConfirmationService]
})
export class ServiciosGarantiaComponent implements OnInit {
  servicios: ServicioGarantia[] = [];
  servicio: ServicioGarantia = this.getEmptyServicio();
  dialogVisible = false;
  loading = false;
  globalFilter: string = '';
  isNew: boolean = true;
  tipoUnidadOptions: DropdownOption[] = [];
  catalogo: { label: string, value: any }[] = [];

  constructor(
    private serviciosGarantiaService: ServiciosGarantiaService,
    private catalogoService: CatalogoService,

    private tiposUnidadService: TiposUnidadService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.loadServicios();
    this.loadTiposUnidad();
    this.loadCatalogo();
  }

  loadCatalogo(): void {
    this.catalogoService.getAllItems().subscribe(
      (data: Catalogo[]) => {
        // console.log('Unidades cargadas:', data);  // Aquí verifica la respuesta de la API
        this.catalogo = data.map(catalogo => ({ label: catalogo.nombre, value: catalogo.idUnidad }));
      },
      (error) => {
        // console.log('Error al cargar unidades:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades' });
      }
    );
  }

  getEmptyServicio(): ServicioGarantia {
    return {
      tipoUnidad: '',
      idUnidad: 0,
      idServicio: 0,
      descripcion: '',
      valorRef: null,
      idMoneda: null,
      tiempoRef: null
    };
  }

  loadTiposUnidad(): void {
    this.tiposUnidadService.getAllTiposUnidad().subscribe(
      (data) => {
        this.tipoUnidadOptions = data.map(tipoUnidad => ({
          label: tipoUnidad.tipoUnidad,
          value: tipoUnidad.tipoUnidad
        }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar tipos de unidad' });
      }
    );
  }

  loadServicios() {
    this.loading = true;
    this.serviciosGarantiaService.getAll().subscribe({
      next: (data) => {
        this.servicios = data;
        this.loading = false;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al cargar los servicios'
        });
        this.loading = false;
      }
    });
  }

  showDialog(servicio?: ServicioGarantia) {
    if (servicio) {
      this.servicio = { ...servicio };
      this.isNew = false;
    } else {
      this.servicio = this.getEmptyServicio();
      this.isNew = true;
    }
    this.dialogVisible = true;
  }

  saveServicio() {
    if (this.isNew) {
      this.serviciosGarantiaService.create(this.servicio).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Servicio creado correctamente'
          });
          this.loadServicios();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al crear el servicio'
          });
        }
      });
    } else {
      this.serviciosGarantiaService.update(
        this.servicio.tipoUnidad,
        this.servicio.idUnidad,
        this.servicio.idServicio,
        this.servicio
      ).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Servicio actualizado correctamente'
          });
          this.loadServicios();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al actualizar el servicio'
          });
        }
      });
    }
  }

  deleteServicio(servicio: ServicioGarantia) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar este servicio?',
      accept: () => {
        this.serviciosGarantiaService.delete(servicio.tipoUnidad, servicio.idUnidad, servicio.idServicio).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Servicio eliminado correctamente'
            });
            this.loadServicios();
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al eliminar el servicio'
            });
          }
        });
      }
    });
  }
}
