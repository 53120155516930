<!-- catalogo-tareas.component.html -->
<div class="card p-4">
  <p-toast></p-toast>
  <p-confirmDialog></p-confirmDialog>

  <div class="flex justify-between items-center mb-4">
      <h2 class="text-2xl font-bold">Gestión de Catálogo de Tareas</h2>
      <button pButton label="Nueva Tarea" icon="pi pi-plus" class="p-button-primary" (click)="showDialog()"></button>
  </div>

  <p-table
      #dt
      [value]="tareas"
      [rows]="10"
      [paginator]="true"
      [tableStyle]="{'min-width': '75rem'}"
      [loading]="loading"
      [rowHover]="true"
      dataKey="idTarea"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
      [rowsPerPageOptions]="[10,25,50]">


      <ng-template pTemplate="header">
          <tr>
              <th pSortableColumn="tipoUnidad">Tipo Unidad <p-sortIcon field="tipoUnidad"></p-sortIcon></th>
              <th pSortableColumn="idUnidad">ID Unidad <p-sortIcon field="idUnidad"></p-sortIcon></th>
              <th pSortableColumn="idTarea">ID Tarea <p-sortIcon field="idTarea"></p-sortIcon></th>
              <th pSortableColumn="descripcion">Descripción <p-sortIcon field="descripcion"></p-sortIcon></th>
              <th pSortableColumn="tiempoEstimado">Tiempo Est. <p-sortIcon field="tiempoEstimado"></p-sortIcon></th>
              <th pSortableColumn="valorRef">Valor Ref. <p-sortIcon field="valorRef"></p-sortIcon></th>
              <th>Acciones</th>
          </tr>
          <tr>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'tipoUnidad', 'contains')"
                         placeholder="Buscar por tipo" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'idUnidad', 'contains')"
                         placeholder="Buscar por ID" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'idTarea', 'contains')"
                         placeholder="Buscar por ID" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'descripcion', 'contains')"
                         placeholder="Buscar por descripción" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'tiempoEstimado', 'contains')"
                         placeholder="Buscar por tiempo" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'valorRef', 'contains')"
                         placeholder="Buscar por valor" class="p-column-filter w-full">
              </th>
              <th></th>
          </tr>
      </ng-template>

      <ng-template pTemplate="body" let-tarea>
          <tr>
              <td>{{tarea.tipoUnidad}}</td>
              <td>{{tarea.idUnidad}}</td>
              <td>{{tarea.idTarea}}</td>
              <td>{{tarea.descripcion}}</td>
              <td>{{tarea.tiempoEstimado}}</td>
              <td>{{tarea.valorRef}}</td>
              <td>
                  <div class="flex gap-2">
                      <button pButton icon="pi pi-pencil" class="p-button-rounded p-button-warning p-button-sm"
                              (click)="showDialog(tarea)"></button>
                      <button pButton icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-sm"
                              (click)="deleteTarea(tarea)"></button>
                  </div>
              </td>
          </tr>
      </ng-template>
  </p-table>

  <p-dialog [(visible)]="dialogVisible" [style]="{width: '50vw'}" [modal]="true"
            [header]="isNew ? 'Nueva Tarea' : 'Editar Tarea'">
      <div class="grid grid-cols-2 gap-4">
          <div class="col-span-1">
              <label class="block mb-2">Tipo de Unidad *</label>
              <p-dropdown [options]="tipoUnidadOptions" [(ngModel)]="tarea.tipoUnidad"
                        [disabled]="!isNew" placeholder="Seleccione tipo" class="w-full"></p-dropdown>
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Unidad *</label>
              <p-dropdown [options]="catalogo" [(ngModel)]="tarea.idUnidad"
                        [disabled]="!isNew" placeholder="Seleccione unidad" class="w-full"></p-dropdown>
          </div>
          <div class="col-span-1" *ngIf="!isNew">
              <label class="block mb-2">ID Tarea</label>
              <input pInputText type="number" [(ngModel)]="tarea.idTarea" class="w-full" disabled />
          </div>
          <div class="col-span-2">
              <label class="block mb-2">Descripción *</label>
              <input pInputText [(ngModel)]="tarea.descripcion" class="w-full" required />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Tiempo Estimado</label>
              <p-inputNumber [(ngModel)]="tarea.tiempoEstimado" mode="decimal" class="w-full"></p-inputNumber>
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Valor de Referencia</label>
              <p-inputNumber [(ngModel)]="tarea.valorRef" mode="decimal" class="w-full"></p-inputNumber>
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Moneda</label>
              <p-dropdown [options]="monedasOptions" [(ngModel)]="tarea.idMoneda"
                        placeholder="Seleccione moneda" class="w-full"></p-dropdown>
          </div>
      </div>

      <ng-template pTemplate="footer">
          <button pButton label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="dialogVisible = false"></button>
          <button pButton label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveTarea()"></button>
      </ng-template>
  </p-dialog>
</div>
