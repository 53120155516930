import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FechaService {
  // Convierte [YYYY, MM, DD] a un objeto Date
  fromBackendDate(backendDate: [number, number, number]): Date {
    return new Date(backendDate[0], backendDate[1] - 1, backendDate[2]);
  }

  // Convierte un objeto Date o cadena ISO a [YYYY, MM, DD]
  toBackendDate(date: Date | string): [number, number, number] {
    const d = typeof date === 'string' ? new Date(date) : date;
    return [d.getFullYear(), d.getMonth() + 1, d.getDate()];
  }

  // Formatea un objeto Date a una cadena legible (opcional)
  toDisplayDate(date: Date): string {
    return date.toISOString().split('T')[0]; // YYYY-MM-DD
  }

  // Convierte una cadena en formato yyyy-mm-dd a un objeto Date
  fromBackendStringDate(backendDate: string): Date {
    const [year, month, day] = backendDate.split('-').map(Number);
    return new Date(year, month - 1, day);
  }
}
