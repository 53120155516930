import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { InspeccionDetalle } from '../models/inspeccion-detalle.model';

@Injectable({
  providedIn: 'root'
})
export class InspeccionDetalleService {
  private baseUrl: string = environment.baseUrl;
  private endpoint: string = '/api/inspecciones-detalle';

  constructor(private http: HttpClient) {}

  // Obtener todos los detalles de inspección
  getAllDetalles(): Observable<InspeccionDetalle[]> {
    return this.http.get<InspeccionDetalle[]>(`${this.baseUrl}${this.endpoint}`);
  }

  // Obtener un detalle de inspección por VIN
  getDetalleByVin(vin: string): Observable<InspeccionDetalle[]> {
    return this.http.get<InspeccionDetalle[]>(`${this.baseUrl}${this.endpoint}/vin/${vin}`);
  }

  // Crear un nuevo detalle de inspección
  createDetalle(inspeccionDetalle: InspeccionDetalle): Observable<InspeccionDetalle> {
    return this.http.post<InspeccionDetalle>(`${this.baseUrl}${this.endpoint}`, inspeccionDetalle);
  }

  // Actualizar un detalle de inspección existente
  updateDetalle(idPreentrega: number, nroOrden: number, inspeccionDetalle: InspeccionDetalle): Observable<InspeccionDetalle> {
    return this.http.put<InspeccionDetalle>(`${this.baseUrl}${this.endpoint}/${idPreentrega}/${nroOrden}`, inspeccionDetalle);
  }

  // Eliminar un detalle de inspección por ID
  deleteDetalle(vin: string, tipoUnidad: string, idUnidad: number, nroMotor: string, idPreentrega: number, nroOrden: number): Observable<void> {
    const url = `${this.baseUrl}${this.endpoint}/${vin}/${tipoUnidad}/${idUnidad}/${nroMotor}/${idPreentrega}/${nroOrden}`;
    return this.http.delete<void>(url);
  }
}
