import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { InspeccionPreentrega } from '../models/inspeccion-preentrega.model';

@Injectable({
  providedIn: 'root'
})
export class InspeccionPreentregaService {
  private baseUrl: string = environment.baseUrl;
  private endpoint: string = '/api/inspecciones-preentrega';

  constructor(private http: HttpClient) {}

  // Obtener todas las inspecciones de preentrega
  getAllInspecciones(): Observable<InspeccionPreentrega[]> {
    return this.http.get<InspeccionPreentrega[]>(`${this.baseUrl}${this.endpoint}`);
  }

  // Obtener una inspección por VIN
  getInspeccionByVin(vin: string): Observable<InspeccionPreentrega> {
    return this.http.get<InspeccionPreentrega>(`${this.baseUrl}${this.endpoint}/vin/${vin}`);
  }

  // Crear una nueva inspección de preentrega
  createInspeccion(inspeccion: InspeccionPreentrega): Observable<InspeccionPreentrega> {
    return this.http.post<InspeccionPreentrega>(`${this.baseUrl}${this.endpoint}`, inspeccion);
  }

  // Actualizar una inspección existente
  updateInspeccion(idPreentrega: number, inspeccion: InspeccionPreentrega): Observable<InspeccionPreentrega> {
    return this.http.put<InspeccionPreentrega>(`${this.baseUrl}${this.endpoint}/${idPreentrega}`, inspeccion);
  }


  /**
   * Eliminar una inspección de preentrega.
   * @param vin VIN de la unidad.
   * @param tipoUnidad Tipo de unidad.
   * @param idUnidad ID de la unidad.
   * @param nroMotor Número de motor.
   * @param idPreentrega ID de preentrega.
   * @returns Observable indicando el resultado de la operación.
   */
  deleteInspeccion(vin: string, tipoUnidad: string, idUnidad: number, nroMotor: string, idPreentrega: number): Observable<void> {
    const url = `${this.baseUrl}${this.endpoint}/${vin}/${tipoUnidad}/${idUnidad}/${nroMotor}/${idPreentrega}`;
    return this.http.delete<void>(url);
  }
}
