import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnidadVendida } from '../models/unidades-vendidas.model'; // Ajusta la ruta según tu estructura
import { environment } from '../environments/environment';
import { FechaService } from './fecha.service';

@Injectable({
  providedIn: 'root',
})
export class UnidadesVendidasService {
  private baseUrl = `${environment.baseUrl}/api/unidades-vendidas`;

  constructor(private http: HttpClient, private fechaService: FechaService) {}

  /**
   * Obtener todas las unidades vendidas
   * @returns Observable con la lista de unidades vendidas
   */
  getAllUnidadesVendidas(): Observable<UnidadVendida[]> {
    return this.http.get<UnidadVendida[]>(this.baseUrl).pipe(
      map((unidades) =>
        unidades.map((unidad) => ({
          ...unidad,
          fechaGarantiaDate: this.fechaService.fromBackendDate(unidad.fechaGarantia), // Convertir a Date
          fechaVentaDate: this.fechaService.fromBackendDate(unidad.fechaVenta), // Convertir a Date
        }))
      )
    );
  }

  /**
   * Obtener una unidad vendida por dominio
   * @param dominio Dominio de la unidad vendida
   * @returns Observable con los datos de la unidad vendida
   */
  getUnidadVendidaByDominio(dominio: string): Observable<UnidadVendida> {
    return this.http.get<UnidadVendida>(`${this.baseUrl}/${dominio}`).pipe(
      map((unidad) => ({
        ...unidad,
        fechaGarantiaDate: this.fechaService.fromBackendDate(unidad.fechaGarantia), // Convertir a Date
        fechaVentaDate: this.fechaService.fromBackendDate(unidad.fechaVenta), // Convertir a Date
      }))
    );
  }

  /**
   * Crear una nueva unidad vendida
   * @param unidadVendida Datos de la unidad vendida
   * @returns Observable con la unidad vendida creada
   */
  createUnidadVendida(unidadVendida: UnidadVendida): Observable<UnidadVendida> {
    const unidadVendidaBackend = {
      ...unidadVendida,
      fechaGarantia: this.fechaService.toBackendDate(unidadVendida.fechaGarantiaDate!), // Convertir Date a [number, number, number]
      fechaVenta: this.fechaService.toBackendDate(unidadVendida.fechaVentaDate!), // Convertir Date a [number, number, number
    };

    return this.http.post<UnidadVendida>(this.baseUrl, unidadVendidaBackend);
  }

  /**x
   * Actualizar una unidad vendida existente
   * @param dominio Dominio de la unidad vendida
   * @param unidadVendida Datos actualizados de la unidad vendida
   * @returns Observable con la unidad vendida actualizada
   */
  updateUnidadVendida(
    dominio: string,
    unidadVendida: UnidadVendida
  ): Observable<UnidadVendida> {
    const unidadVendidaBackend = {
      ...unidadVendida,
      fechaGarantia: this.fechaService.toBackendDate(unidadVendida.fechaGarantiaDate!), // Convertir Date a [number, number, number]
      fechaVenta: this.fechaService.toBackendDate(unidadVendida.fechaVentaDate!), // Convertir Date a [number, number, number
    };

    return this.http.put<UnidadVendida>(
      `${this.baseUrl}/${dominio}`,
      unidadVendidaBackend
    ).pipe(
      map((unidad) => ({
        ...unidad,
        fechaGarantiaDate: this.fechaService.fromBackendDate(unidad.fechaGarantia), // Convertir a Date
        fechaVentaDate: this.fechaService.fromBackendDate(unidad.fechaVenta), // Convertir a Date
      }))
    );
  }

  /**
   * Eliminar una unidad vendida
   * @param dominio Dominio de la unidad vendida
   * @returns Observable<void> indicando éxito
   */
  deleteUnidadVendida(dominio: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${dominio}`);
  }

  /**
   * Buscar unidades vendidas por un término
   * @param termino Término de búsqueda
   * @returns Observable con las unidades vendidas que coincidan con el término
   */
  searchUnidadesVendidas(termino: string): Observable<UnidadVendida[]> {
    return this.http.get<UnidadVendida[]>(
      `${this.baseUrl}/buscar?termino=${encodeURIComponent(termino)}`
    ).pipe(
      map((unidades) =>
        unidades.map((unidad) => ({
          ...unidad,
          fechaGarantiaDate: this.fechaService.fromBackendDate(unidad.fechaGarantia), // Convertir a Date
          fechaVentaDate: this.fechaService.fromBackendDate(unidad.fechaVenta), // Convertir a Date
        }))
      )
    );
  }
}
