// catalogo-tareas.component.ts
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { CatalogoTareasService } from '../../services/catalogo-tareas.service';
import { TiposUnidadService } from '../../services/tipos-unidad.service';
import { CatalogoService } from '../../services/catalogo.service';
import { MonedasService } from '../../services/moneda.service';
import { CatalogoTareas } from '../../models/catalogo-tareas.model';
import { Catalogo } from '../../models/catalogo.model';
import { Moneda } from '../../models/moneda.model';
import { BaseComponent } from '../../base/base.component';

interface DropdownOption {
  label: string;
  value: string | number;
}

@Component({
  selector: 'app-catalogo-tareas',
  templateUrl: './catalogo-tareas.component.html',
  providers: [ConfirmationService]
})
export class CatalogoTareasComponent extends BaseComponent implements OnInit {
  tareas: CatalogoTareas[] = [];
  tarea: CatalogoTareas = this.getEmptyTarea();
  dialogVisible = false;
  loading = false;
  globalFilter: string = '';
  isNew: boolean = true;

  tipoUnidadOptions: DropdownOption[] = [];
  catalogo: DropdownOption[] = [];
  monedasOptions: DropdownOption[] = [];

  constructor(
    private catalogoTareasService: CatalogoTareasService,
    private tiposUnidadService: TiposUnidadService,
    private catalogoService: CatalogoService,
    private monedasService: MonedasService,
    private confirmationService: ConfirmationService
  ) { super(); }

  ngOnInit() {
    this.loadTareas();
    this.loadTiposUnidad();
    this.loadCatalogo();
    this.loadMonedas();
  }

  getEmptyTarea(): CatalogoTareas {
    return {
      tipoUnidad: '',
      idUnidad: 0,
      idTarea: 0,
      descripcion: '',
      tiempoEstimado: undefined,
      valorRef: undefined,
      idMoneda: ''
    };
  }

  loadTiposUnidad(): void {
    this.tiposUnidadService.getAllTiposUnidad().subscribe(
      (data) => {
        this.tipoUnidadOptions = data.map(tipoUnidad => ({
          label: tipoUnidad.tipoUnidad,
          value: tipoUnidad.tipoUnidad
        }));
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  loadCatalogo(): void {
    this.catalogoService.getAllItems().subscribe(
      (data: Catalogo[]) => {
        this.catalogo = data.map(catalogo => ({ label: catalogo.nombre, value: catalogo.idUnidad }));
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  loadMonedas(): void {
    this.monedasService.getAllMonedas().subscribe(
      (data: Moneda[]) => {
        this.monedasOptions = data.map(moneda => ({
          label: `${moneda.nombre} (${moneda.simbolo})`,
          value: moneda.idMoneda
        }));
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  loadTareas() {
    this.loading = true;
    this.catalogoTareasService.getAllCatalogoTareas().subscribe({
      next: (data) => {
        this.tareas = data;
        this.loading = false;
      },
      error: (error) => {
        this.handleError(error);
        this.loading = false;
      }
    });
  }

  showDialog(tarea?: CatalogoTareas) {
    if (tarea) {
      this.tarea = { ...tarea };
      this.isNew = false;
    } else {
      this.tarea = this.getEmptyTarea();
      this.isNew = true;
    }
    this.dialogVisible = true;
  }

  saveTarea() {
    if (this.isNew) {
      this.catalogoTareasService.create(this.tarea).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Tarea creada correctamente'
          });
          this.loadTareas();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    } else {
      this.catalogoTareasService.update(
        this.tarea.tipoUnidad,
        this.tarea.idUnidad,
        this.tarea.idTarea,
        this.tarea
      ).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Tarea actualizada correctamente'
          });
          this.loadTareas();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    }
  }

  deleteTarea(tarea: CatalogoTareas) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar esta tarea?',
      accept: () => {
        this.catalogoTareasService.delete(tarea.tipoUnidad, tarea.idUnidad, tarea.idTarea).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Tarea eliminada correctamente'
            });
            this.loadTareas();
          },
          error: (error) => {
            this.handleError(error);
          }
        });
      }
    });
  }
}
