import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { TiposUnidad } from '../models/tipos-unidad.model';

@Injectable({
  providedIn: 'root'
})
export class TiposUnidadService {
  private baseUrl: string = environment.baseUrl;
  private endpoint: string = '/api/tipos-unidad';

  constructor(private http: HttpClient) {}

  // Obtener todos los tipos de unidad
  getAllTiposUnidad(): Observable<TiposUnidad[]> {
    return this.http.get<TiposUnidad[]>(`${this.baseUrl}${this.endpoint}`);
  }


    /**
   * Obtener un tipo de unidad por su ID
   * @param tipoUnidad ID del tipo de unidad
   * @returns Observable con el tipo de unidad
   */
    getTipoUnidadById(tipoUnidad: string): Observable<TiposUnidad> {
      return this.http.get<TiposUnidad>(`${this.baseUrl}${this.endpoint}/${tipoUnidad}`);
    }

    /**
     * Crear un nuevo tipo de unidad
     * @param tipoUnidad Datos del nuevo tipo de unidad
     * @returns Observable con la respuesta
     */
    createTipoUnidad(tipoUnidad: TiposUnidad): Observable<TiposUnidad> {
      return this.http.post<TiposUnidad>(`${this.baseUrl}${this.endpoint}`, tipoUnidad);
    }

    /**
     * Actualizar un tipo de unidad existente
     * @param tipoUnidad Datos actualizados del tipo de unidad
     * @returns Observable con la respuesta
     */
    updateTipoUnidad(tipoUnidad: TiposUnidad): Observable<TiposUnidad> {
      return this.http.put<TiposUnidad>(`${this.baseUrl}${this.endpoint}`, tipoUnidad);
    }

    /**
     * Eliminar un tipo de unidad
     * @param tipoUnidad ID del tipo de unidad
     * @returns Observable con la respuesta
     */
    deleteTipoUnidad(tipoUnidad: string): Observable<any> {
      return this.http.delete(`${this.baseUrl}${this.endpoint}/${tipoUnidad}`);
    }


    // Manejo de errores HTTP
    private handleError(error: HttpErrorResponse) {
      // Aquí puedes agregar lógica adicional de manejo de errores
      return throwError(() => new Error('Error en la comunicación con el servidor.'));
    }
}
