// tareas-servicios.component.ts
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService, ConfirmationService } from 'primeng/api';

import { TareasServiciosService } from '../../services/tareas-servicios.service';
import { TiposUnidadService } from '../../services/tipos-unidad.service';
import { CatalogoService } from '../../services/catalogo.service';
import { CatalogoTareasService } from '../../services/catalogo-tareas.service';
import { ServiciosGarantiaService } from '../../services/servicios-garantia.service';

import { TareaServicio } from '../../models/tarea-servicio.model';
import { Catalogo } from '../../models/catalogo.model';
import { ServicioGarantia } from '../../models/servicios-garantia.model';

interface DropdownOption {
  label: string;
  value: string | number;
}

@Component({
  selector: 'app-tareas-servicios',
  templateUrl: './tareas-servicios.component.html',
  styleUrls: ['./tareas-servicios.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class TareasServiciosComponent implements OnInit {
  tareasServicios: TareaServicio[] = [];
  tareaServicio: TareaServicio = this.getEmptyTareaServicio();
  dialogVisible = false;
  loading = false;
  globalFilter: string = '';
  isNew: boolean = true;

  tipoUnidadOptions: DropdownOption[] = [];
  catalogo: DropdownOption[] = [];
  tareas: DropdownOption[] = [];
  serviciosGarantiaOptions: DropdownOption[] = [];

  constructor(
    private tareasServiciosService: TareasServiciosService,
    private tiposUnidadService: TiposUnidadService,
    private catalogoService: CatalogoService,
    private catalogoTareasService: CatalogoTareasService,
    private serviciosGarantiaService: ServiciosGarantiaService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.loadTareasServicios();
    this.loadTiposUnidad();
    this.loadCatalogo();
    this.loadTareas();
    this.loadServiciosGarantia();
  }

  getEmptyTareaServicio(): TareaServicio {
    return {
      tipoUnidad: '',
      idUnidad: 0,
      idTarea: 0,
      idServicio: 0,
      tipoAplicacion: ''
    };
  }

  loadTiposUnidad(): void {
    this.tiposUnidadService.getAllTiposUnidad().subscribe(
      (data) => {
        this.tipoUnidadOptions = data.map(tipoUnidad => ({
          label: tipoUnidad.tipoUnidad,
          value: tipoUnidad.tipoUnidad
        }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar tipos de unidad' });
      }
    );
  }

  loadCatalogo(): void {
    this.catalogoService.getAllItems().subscribe(
      (data: Catalogo[]) => {
        this.catalogo = data.map(catalogo => ({ label: catalogo.nombre, value: catalogo.idUnidad }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades' });
      }
    );
  }

  loadTareas() {
    this.loading = true;
    this.catalogoTareasService.getAllCatalogoTareas().subscribe({
      next: (data) => {
        this.tareas = data.map(tarea => ({
          label: tarea.descripcion,
          value: tarea.idTarea
        }));
        this.loading = false;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al cargar las tareas'
        });
        this.loading = false;
      }
    });
  }

  loadServiciosGarantia(): void {
    this.serviciosGarantiaService.getAllServiciosGarantia().subscribe({
      next: (data: ServicioGarantia[]) => {
        this.serviciosGarantiaOptions = data.map(servicio => ({
          label: servicio.descripcion,
          value: servicio.idServicio
        }));
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar servicios de garantía' });
      }
    });
  }

  loadTareasServicios() {
    this.loading = true;
    this.tareasServiciosService.getAll().subscribe({
      next: (data) => {
        this.tareasServicios = data;
        this.loading = false;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al cargar las tareas de servicio'
        });
        this.loading = false;
      }
    });
  }

  showDialog(tareaServicio?: TareaServicio) {
    if (tareaServicio) {
      this.tareaServicio = { ...tareaServicio };
      this.isNew = false;
    } else {
      this.tareaServicio = this.getEmptyTareaServicio();
      this.isNew = true;
    }
    this.dialogVisible = true;
  }

  saveTareaServicio() {
    if (this.isNew) {
      this.tareasServiciosService.create(this.tareaServicio).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Tarea servicio creada correctamente'
          });
          this.loadTareasServicios();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al crear la tarea servicio'
          });
        }
      });
    } else {
      this.tareasServiciosService.update(
        this.tareaServicio.tipoUnidad,
        this.tareaServicio.idUnidad,
        this.tareaServicio.idTarea,
        this.tareaServicio.idServicio,
        this.tareaServicio
      ).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Tarea servicio actualizada correctamente'
          });
          this.loadTareasServicios();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al actualizar la tarea servicio'
          });
        }
      });
    }
  }

  deleteTareaServicio(tareaServicio: TareaServicio) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar esta tarea servicio?',
      accept: () => {
        this.tareasServiciosService.delete(
          tareaServicio.tipoUnidad,
          tareaServicio.idUnidad,
          tareaServicio.idTarea,
          tareaServicio.idServicio
        ).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Tarea servicio eliminada correctamente'
            });
            this.loadTareasServicios();
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al eliminar la tarea servicio'
            });
          }
        });
      }
    });
  }
}
