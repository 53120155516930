import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { CatalogoTareas } from '../models/catalogo-tareas.model';

// export interface CatalogoTareasDTO {
//   tipoUnidad: string;        // Tipo de unidad (e.g., "VHO")
//   idUnidad: number;          // ID de la unidad
//   idTarea: number;           // ID de la tarea
//   descripcion: string;       // Descripción de la tarea
//   tiempoEstimado?: number;   // Tiempo estimado en horas (opcional)
//   valorRef?: number;         // Valor de referencia (opcional)
// }

@Injectable({
  providedIn: 'root',
})
export class CatalogoTareasService {
  private baseUrl = `${environment.baseUrl}/api/catalogo-tareas`;


  constructor(private http: HttpClient) {}

  // Obtener todas las tareas del catálogo
  getAllCatalogoTareas(): Observable<CatalogoTareas[]> {
    return this.http.get<CatalogoTareas[]>(this.baseUrl);
  }

  // // Obtener una tarea específica por ID compuesto
  // getCatalogoTareaById(
  //   tipoUnidad: string,
  //   idUnidad: number,
  //   idTarea: number
  // ): Observable<CatalogoTareas> {
  //   const url = `${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idTarea}`;
  //   return this.http.get<CatalogoTareas>(url);
  // }

  // // Crear una nueva tarea en el catálogo
  // createCatalogoTarea(
  //   tarea: CatalogoTareas
  // ): Observable<CatalogoTareas> {
  //   return this.http.post<CatalogoTareas>(this.baseUrl, tarea);
  // }

  // // Actualizar una tarea existente en el catálogo
  // updateCatalogoTarea(
  //   tipoUnidad: string,
  //   idUnidad: number,
  //   idTarea: number,
  //   tarea: CatalogoTareas
  // ): Observable<CatalogoTareas> {
  //   const url = `${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idTarea}`;
  //   return this.http.put<CatalogoTareas>(url, tarea);
  // }

  // // Eliminar una tarea del catálogo
  // deleteCatalogoTarea(
  //   tipoUnidad: string,
  //   idUnidad: number,
  //   idTarea: number
  // ): Observable<void> {
  //   const url = `${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idTarea}`;
  //   return this.http.delete<void>(url);
  // }

  getById(tipoUnidad: string, idUnidad: number, idTarea: number): Observable<CatalogoTareas> {
    return this.http.get<CatalogoTareas>(`${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idTarea}`);
  }

  create(tarea: CatalogoTareas): Observable<CatalogoTareas> {
    return this.http.post<CatalogoTareas>(this.baseUrl, tarea);
  }

  update(tipoUnidad: string, idUnidad: number, idTarea: number, tarea: CatalogoTareas): Observable<CatalogoTareas> {
    return this.http.put<CatalogoTareas>(`${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idTarea}`, tarea);
  }

  delete(tipoUnidad: string, idUnidad: number, idTarea: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idTarea}`);
  }

  getByUnidad(tipoUnidad: string, idUnidad: number): Observable<CatalogoTareas[]> {
    return this.http.get<CatalogoTareas[]>(`${this.baseUrl}/unidad/${tipoUnidad}/${idUnidad}`);
  }

  search(termino: string): Observable<CatalogoTareas[]> {
    return this.http.get<CatalogoTareas[]>(`${this.baseUrl}/buscar?termino=${termino}`);
  }
}
