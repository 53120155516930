<p-toast position="top-center"></p-toast>
<p-confirmDialog></p-confirmDialog>

<!-- Búsqueda de Unidad Vendida -->
<p-card class="" [style]="{ 'margin-top': '0px', 'padding': '0px', 'margin-right': '16px', 'margin-left': '16px', 'margin-bottom': '8px', 'background-color': 'transparent !important' }">
  <div class="flex gap-4 items-center">
    <p-autoComplete [(ngModel)]="dominioSearch" [suggestions]="dominiosCargados"
    [showClear]="true"
    [minLength]="1" placeholder="Buscar por Dominio"  class="p-component w-1/2 mb-4 rounded-lg"
      (completeMethod)="searchDominioIncremental($event)"
      (onClear)="cancelEdit()"
      (onSelect)="searchUnidadVendida($event)" [dropdown]="true"  [style]="{ 'width': '100%' }"
      >
    </p-autoComplete>

    <button pButton icon="pi pi-search" label="Buscar" (click)="searchUnidadVendidaDominio()" class="p-button-primary mb-4"></button>
  </div>

  <!-- Fila de botones: Nuevo, Eliminar, Editar -->
  <div class="flex gap-2 items-center">
    <button pButton icon="pi pi-plus" label="Nuevo" (click)="this.vinSearch = ''; nuevoRegistro();" styleClass="p-button-sm" size="small" class="p-button-sm"></button>
    <!-- p-button-success -->
    <button pButton icon="pi pi-trash" label="Eliminar" (click)="deleteUnidadVendida()" class="p-button-danger" [disabled]="!selectedUnidadVendida"></button>
    <button pButton icon="pi pi-pencil" label="Editar" (click)="editarUnidadVendida()" class="p-button-warning" [disabled]="!selectedUnidadVendida"></button>
    <button pButton icon="pi pi-times" (click)="cancelEdit()" class="p-2 p-button-primary btn btn-danger"
      [disabled]="!isEditing && !isCreating">
      Cancelar
    </button>
    <button pButton icon="pi pi-eye" label="Ver Historial de Servicio" (click)="callHistorialServicios()" class="p-button-primary"
      [disabled]="!selectedUnidadVendida"></button>
      <button pButton icon="pi pi-eye" label="Ver Maestro de Unidades" (click)="callMaestroUnidades()" class="p-button-primary"
      [disabled]="!selectedUnidadVendida"></button>

    <!-- Mensaje de error para la eliminación de una Unidad Vendida -->
    <div *ngIf="deleteError" class="text-red-600">
      <small>{{ deleteError }}</small>
    </div>

    <!-- Mensaje de éxito para la eliminación de una Unidad Vendida -->
    <div *ngIf="deleteSuccess" class="text-green-600">
      <small>{{ deleteSuccess }}</small>
    </div>
  </div>
</p-card>

<!-- Formulario para crear o editar una Unidad Vendida -->
<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <form [formGroup]="unidadVendidaForm" (ngSubmit)="isCreating ? createUnidadVendida() : updateUnidadVendida()">
    <div class="mx-auto rounded-3xl w-full">
      <h1 class="text-4xl font-semibold mb-4">Maestro de Unidades Vendidas</h1>
      <div class="rounded p-0 mt-0 mb-8">
        <h2 class="text-2xl font-semibold mb-2">Datos identificatorios</h2>
        <div class="grid grid-cols-4 gap-4 mb-6">
          <div class="p-field">
            <label for="vin" class="block text-white font-semibold">VIN *</label>
            <!-- [(ngModel)]="vinSearch" -->
            <p-autoComplete id="vin" formControlName="vin" [suggestions]="vinsCargados" [disabled]="isEditing" 
              [showClear]="true" [minLength]="1" placeholder="Buscar por VIN" class="p-component w-full p-2 rounded-lg"
              (completeMethod)="searchVin($event)" (onSelect)="searchUnidadAndLoadAttrs($event)" [dropdown]="true">
            </p-autoComplete>
            <!-- Mensaje de error para VIN -->
            <div *ngIf="unidadVendidaForm.get('vin')?.invalid && unidadVendidaForm.get('vin')?.touched">
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('vin')?.errors?.['maxlength']">
                El VIN no puede exceder los 24 caracteres.
              </small>
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('vin')?.errors?.['required']">
                El VIN es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="nroMotor" class="block text-white font-semibold">Número de Motor *</label>
            <input id="nroMotor" formControlName="nroMotor" pInputText class=" p-element p-component w-full p-2 border rounded-lg" [disabled]="true" />
            <!-- Mensaje de error para Número de Motor -->
            <div *ngIf="unidadVendidaForm.get('nroMotor')?.invalid && unidadVendidaForm.get('nroMotor')?.touched">
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('nroMotor')?.errors?.['maxlength']">
                El Número de Motor no puede exceder los 24 caracteres.
              </small>
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('nroMotor')?.errors?.['required']">
                El Número de Motor es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="tipoUnidad" class="block text-white font-semibold">Tipo de Unidad *</label>
            <input id="tipoUnidad" formControlName="tipoUnidad" pInputText class=" p-element p-component w-full p-2 border rounded-lg" />
            <!-- Mensaje de error para Tipo de Unidad -->
            <div *ngIf="unidadVendidaForm.get('tipoUnidad')?.invalid && unidadVendidaForm.get('tipoUnidad')?.touched">
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('tipoUnidad')?.errors?.['maxlength']">
                El Tipo de Unidad no puede exceder los 3 caracteres.
              </small>
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('tipoUnidad')?.errors?.['required']">
                El Tipo de Unidad es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="idUnidad" class="block text-white font-semibold">ID Unidad *</label>
            <input id="idUnidad" formControlName="idUnidad" pInputText class=" p-element p-component w-full p-2 border rounded-lg" />
            <!-- Mensaje de error para ID Unidad -->
            <div *ngIf="unidadVendidaForm.get('idUnidad')?.invalid && unidadVendidaForm.get('idUnidad')?.touched">
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('idUnidad')?.errors?.['maxlength']">
                El ID Unidad no puede exceder los 20 caracteres.
              </small>
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('idUnidad')?.errors?.['required']">
                El ID Unidad es requerido
              </small>
            </div>
          </div>
        </div>
      </div>



      <div class="border border-gray-300 rounded p-2 mb-8">
        <h2 class="text-2xl font-semibold mb-4">Detalles de Venta</h2>
        <div class="grid grid-cols-3 gap-4 mb-6">
          <div class="p-field">
            <label for="dominio" class="block text-white font-semibold">Dominio *</label>
            <input id="dominio" formControlName="dominio" pInputText class="p-element p-component w-full p-2 border rounded-lg" />
            <!-- Mensaje de error para Dominio -->
            <div *ngIf="unidadVendidaForm.get('dominio')?.invalid && unidadVendidaForm.get('dominio')?.touched">
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('dominio')?.errors?.['maxlength']">
                El Dominio no puede exceder los 20 caracteres.
              </small>
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('dominio')?.errors?.['required']">
                El Dominio es requerido
              </small>
            </div>
          </div>

          <div class="p-field">
            <label for="idAgencia" class="block text-white font-semibold">ID Agencia *</label>

            <!-- <input id="idAgencia" formControlName="idAgencia" pInputText class=" p-element p-component w-full p-2 border rounded-lg" />
            <div *ngIf="unidadVendidaForm.get('idAgencia')?.invalid && unidadVendidaForm.get('idAgencia')?.touched">
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('idAgencia')?.errors?.['maxlength']">
                El ID Agencia no puede exceder los 20 caracteres.
              </small>
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('idAgencia')?.errors?.['required']">
                El ID Agencia es requerido
              </small>
            </div> -->


            <p-dropdown id="idAgencia" formControlName="idAgencia"
            class="w-full p-dropdown-sm p-2"
            [ngClass]="{'p-invalid': unidadVendidaForm.get('idAgencia')?.invalid && (unidadVendidaForm.get('idAgencia')?.dirty || unidadVendidaForm.get('idAgencia')?.touched)}"
            [showClear]="true"
            [filter]="true"
            filterPlaceholder="Buscar Agencia"
            [options]="agencias" autoWidth="false"
            [style]="{ width: '15rem',  height: '2.6rem' }"></p-dropdown>
            <div *ngIf="unidadVendidaForm.get('idAgencia')?.invalid && unidadVendidaForm.get('idAgencia')?.touched">
              <small class="text-red-600">
                El ID Agencia es requerido
              </small>
            </div>


          </div>
          <div class="p-field">
            <label for="kmEntrega" class="block text-white font-semibold">Kilometraje de Entrega *</label>
            <input id="kmEntrega" formControlName="kmEntrega" pInputText class=" p-element p-component w-full p-2 border rounded-lg" />
            <!-- Mensaje de error para Kilometraje de Entrega -->
            <div *ngIf="unidadVendidaForm.get('kmEntrega')?.invalid && unidadVendidaForm.get('kmEntrega')?.touched">
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('kmEntrega')?.errors?.['maxlength']">
                El Kilometraje de Entrega no puede exceder los 20 caracteres.
              </small>
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('kmEntrega')?.errors?.['required']">
                El Kilometraje de Entrega es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="kmGarantia" class="block text-white font-semibold">Kilometraje de Garantía *</label>
            <input id="kmGarantia" formControlName="kmGarantia" pInputText class=" p-element p-component w-full p-2 border rounded-lg" />
            <!-- Mensaje de error para Kilometraje de Garantía -->
            <div *ngIf="unidadVendidaForm.get('kmGarantia')?.invalid && unidadVendidaForm.get('kmGarantia')?.touched">
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('kmGarantia')?.errors?.['maxlength']">
                El Kilometraje de Garantía no puede exceder los 20 caracteres.
              </small>
              <small class="text-red-600" *ngIf="unidadVendidaForm.get('kmGarantia')?.errors?.['required']">
                El Kilometraje de Garantía es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="fechaGarantia" class="block text-white font-semibold">Fecha de Garantía *</label>

            <p-calendar id="fechaGarantiaDate" formControlName="fechaGarantiaDate"
            placeholder="dd/mm/yy" [dateFormat]="'dd/mm/yy'"
            [showIcon]="true"
            inputStyleClass="w-full p-2 border rounded-lg"
            [style]="{ minWidth: '50px', width: '100%' }"
            appendTo="body"
            [readonlyInput]="!isEditing && !isCreating"></p-calendar>
          </div>
          <div class="p-field">
            <label for="fechaVentaDate" class="block text-white font-semibold">Fecha de Venta *</label>

            <p-calendar id="fechaVentaDate" formControlName="fechaVentaDate"
            placeholder="dd/mm/yy" [dateFormat]="'dd/mm/yy'"
            [showIcon]="true"
            inputStyleClass="w-full p-2 border rounded-lg"
            [style]="{ minWidth: '50px', width: '100%' }"
            appendTo="body"
            [readonlyInput]="!isEditing && !isCreating"></p-calendar>
          </div>
        </div>
      </div>


      <!-- Lista de Titulares -->
      <div class="border border-gray-300 rounded mb-8">
        <h2 class="text-2xl font-semibold mb-4">Lista de Titulares</h2>
        <!-- [tableStyle]="{ 'background-color': '#223A73' }"  [style]="{'background-color':'#223A73' }" -->
        <p-table [value]="titulares.controls" class="mb-6 overflow-visible w-full">
          <ng-template pTemplate="header">
            <tr class="bg-gray-200 text-sm font-medium">
              <th class="px-4 py-3 w-1/6">Información Básica</th>
              <th class="px-4 py-3 w-1/3">Dirección</th>
              <th class="px-4 py-3 w-1/4">Ubicación</th>
              <th class="px-4 py-3 w-1/12">Acciones</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body"  [style]="{'background-color':'#223A73' }"  let-titular let-i="rowIndex">
            <tr [formGroup]="titular" class="text-sm hover:bg-gray-100">
              <!-- Información Básica -->
              <td class="px-4 py-2">
                <div class="mb-2">
                  <label class="font-medium">Fecha de Titularidad:</label>
                  <p-calendar
                  placeholder="dd/mm/yy"
                  [dateFormat]="'dd/mm/yy'"
                  appendTo="body"
                  formControlName="fechaTitularidadDate"
                  class="w-full rounded-lg"
                  [showIcon]="true"
                ></p-calendar>
                <!-- (onSelect)="onTitularDateSelect($event, i)" -->
                </div>
                <div class="mb-2">
                  <label class="font-medium">Nombre:</label>
                  <input type="text" pInputText formControlName="nombre" class="rounded-lg w-full truncate" />
                </div>
                <div class="mb-2">
                  <label class="font-medium">CUIT/CUIL:</label>
                  <input type="text" pInputText formControlName="cuitCuil" class="rounded-lg w-full truncate" />
                </div>
                <div>
                  <label class="font-medium">Correo:</label>
                  <input type="email" pInputText formControlName="correo" class="rounded-lg w-full truncate" />
                </div>
              </td>
              <!-- Dirección -->
              <td class="px-4 py-2">
                <div class="mb-2">
                  <label class="font-medium">Calle:</label>
                  <input type="text" placeholder="Calle" formControlName="calle" class="rounded-lg w-full truncate" />
                </div>
                <div>
                  <label class="font-medium">Número:</label>
                  <input type="number" placeholder="Número" formControlName="numero" class="rounded-lg w-full truncate" />
                </div>
              </td>
              <!-- Ubicación -->
              <td class="px-4 py-2">
                <div class="flex gap-2">
                  <label class="font-medium">Provincia:</label>
                  <p-dropdown formControlName="provincia"
                  [showClear]="true"
                  [filter]="true"
                  placeholder="Buscar Provincia"
                  filterPlaceholder="Buscar Provincia"[options]="provinciasOptions" class="w-full"></p-dropdown>
                </div>
                <div class="mb-2">
                  <label class="font-medium">Localidad:</label>
                  <input type="text" pInputText formControlName="localidad" class="rounded-lg w-full truncate" />
                </div>
                <div>
                  <label class="font-medium">Código Postal:</label>
                  <input type="text" pInputText formControlName="codPostal" class=" rounded-lg w-full truncate" />
                </div>
              </td>
              <!-- Acciones -->
              <td class="px-4 py-2 text-center">
                <button
                  pButton
                  icon="pi pi-trash"
                  type="button"
                  (click)="removeTitular(i)"
                  class="p-button-rounded p-button-danger p-button-sm"
                  [disabled]="!isEditing && !isCreating"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>



        <div class="mt-6">
          <button pButton label="Añadir Titular" type="button" (click)="addTitular()" [disabled]="!isEditing && !isCreating" class="p-button-primary"></button>
        </div>
      </div>




      <div class="flex gap-2">
        <button pButton type="submit" class="p-2 btn btn-danger" [disabled]="!isEditing && !isCreating">Guardar</button>
        <button pButton (click)="cancelEdit()" class="p-2 p-button-primary btn btn-danger" [disabled]="!isEditing && !isCreating">Cancelar</button>
      </div>
    </div>
  </form>
</p-card>

<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <!-- Tabla de unidades vendidas -->
  <p-table [value]="unidadesVendidas"
    styleClass="p-datatable-striped"
    [scrollable]="true"
  >
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
        Lista de Unidades Vendidas
    </div>
  </ng-template>
  <ng-template pTemplate="header">
      <tr>
        <th>Dominio</th>
        <th>VIN</th>
        <th>Tipo de Unidad</th>
        <th>ID Unidad</th>
        <th>Número de Motor</th>
        <th>Consultar</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-unidadVendida>
      <tr>
        <td>{{ unidadVendida.dominio }} </td>
        <td>{{ unidadVendida.vin }}</td>
        <td>{{ unidadVendida.tipoUnidad }}</td>
        <td>{{ unidadVendida.idUnidad }}</td>
        <td>{{ unidadVendida.nroMotor }}</td>
        <td>
            <button pButton icon="pi pi-search" (click)="consultarUnidadVendida(unidadVendida)" class="p-button-primary"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
