import { CatalogoService } from './../../../services/catalogo.service';
import { TiposUnidadService } from '../../../services/tipos-unidad.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UnidadService } from '../../../services/unidad-maestro.service';
import { AgenciasService } from '../../../services/agencias.service';
import { AcuerdoService } from '../../../services/acuerdos.service'; // Importar AcuerdoService
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { Unidad } from '../../../models/unidad-maestro.model';
import { Agencia } from '../../../models/agencias.model';
import { Acuerdo } from '../../../models/acuerdo.model'; // Importar Acuerdo
import { InspeccionPreentrega } from '../../../models/inspeccion-preentrega.model';
import { InspeccionDetalle } from '../../../models/inspeccion-detalle.model';
import { InspeccionDetalleService } from '../../../services/inspeccion-detalle.service';
import { InspeccionPreentregaService } from '../../../services/inspecciones-preentrega.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Catalogo } from '../../../models/catalogo.model';
import { ProformaService } from '../../../services/proformas.service';
import { BillOfLandingService } from '../../../services/bill-of-landing.service'; // Importar el servicio de Bill of Landing
import { EmpaqueService } from '../../../services/empaques.service';
import { FacturaService } from '../../../services/factura.service'; // Importar el servicio de Factura
import { SolicitudesService } from '../../../services/solicitud.service';
import { Solicitud } from '../../../models/solicitud.model';
import { MonedasService } from '../../../services/moneda.service';
import { Moneda } from '../../../models/moneda.model';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, catchError, of, Observable, forkJoin, map, tap, throwError } from 'rxjs';
import { BaseComponent } from '../../../base/base.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-unidad-crud',
  templateUrl: './unidad-crud.component.html',
  styleUrls: ['./unidad-crud.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class UnidadCrudComponent extends BaseComponent implements OnInit {
  unidadForm: FormGroup;
  unidades: Unidad[] = [];
  vinsCargados: string[] = [];
  isCreateMode: boolean = false;

  monedasOptions: { label: string; value: any }[] = []; 
  proformaOptions: { label: string; value: any }[] = [];
  catalogo: { label: string; value: any }[] = [];
  agencias: { label: string; value: any }[] = [];
  acuerdos: { label: string; value: any }[] = [];
  tipoUnidadOptions: { label: string; value: any }[] = [];
  billOfLandingOptions: { label: string; value: any }[] = [];
  empaqueOptions: { label: string; value: any }[] = [];
  facturaOptions: { label: string; value: any }[] = [];
  solicitudesOptions: { label: string; value: any }[] = [];

  // *** CAMBIO (dropdown) se definió con label 'Daño' y value 'D', etc.
  incidenciaOptions: any[] = [
    { label: 'Daño', value: 'D' },
    { label: 'Faltante', value: 'F' },
    { label: 'Otro', value: 'O' },
  ];

  inspeccion: InspeccionPreentrega | null = null;
  inspeccionDetalle: InspeccionDetalle[] = [];
  inspeccionDetalleOri: InspeccionDetalle[] = [];

  customcard: string = 'customcard'; 
  deleteSuccess: string = '';
  deleteError: string = '';
  vinSearch: string = '';
  selectedUnidad: Unidad | null = null;
  isEditing = false;
  isCreating = false;

  constructor(
    private fb: FormBuilder,
    private unidadService: UnidadService,
    private agenciasService: AgenciasService,
    private proformaService: ProformaService,
    private facturaService: FacturaService,
    private solicitudService: SolicitudesService,
    private router: Router,
    private route: ActivatedRoute,
    private catalogoService: CatalogoService,
    private acuerdoService: AcuerdoService,
    private billOfLandingService: BillOfLandingService,
    private monedasService: MonedasService,
    private tiposUnidadService: TiposUnidadService,
    private confirmationService: ConfirmationService,
    private empaqueService: EmpaqueService,
    private inspeccionPreentregaService: InspeccionPreentregaService,
    private inspeccionDetalleService: InspeccionDetalleService
  ) {
    super();
    this.unidadForm = this.fb.group({
      vin: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(24)]),
      tipoUnidad: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(3)]),
      idUnidad: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(20)]),
      nroMotor: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(24)]),
      idEmpaque: new FormControl({ value: null, disabled: true }),
      idBl: new FormControl({ value: '', disabled: true }, Validators.required),
      idFactura: new FormControl({ value: '', disabled: true }, Validators.required),
      idSolicitud: new FormControl({ value: null, disabled: true }, Validators.required),
      idProforma: new FormControl({ value: '', disabled: true }, Validators.required),
      idAgencia: new FormControl({ value: null, disabled: true }),
      fechaEntrega: new FormControl({ value: null, disabled: true }),
      tipoAcuerdo: new FormControl({ value: '', disabled: true }),
      fechaAcuerdo: new FormControl({ value: null, disabled: true }),
      certDnrpa: new FormControl({ value: '', disabled: true }),
      cert01: new FormControl({ value: null, disabled: true }),
      dominio: new FormControl({ value: '', disabled: true }),
      qrIdentifier: new FormControl({ value: '', disabled: true }),
      incidencias: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadSolicitudes();
      this.loadMaestroUnidades();
      this.loadAgencias();
      this.loadAcuerdos();
      this.loadTiposUnidad();
      this.loadCatalogo();
      this.loadProformas();
      this.loadBillOfLandings();
      this.loadEmpaques();
      this.loadFacturas();
      this.loadMonedas();

      this.isCreateMode = this.route.snapshot.url.some(segment => segment.path === 'create');

      if (this.isCreateMode) {
        if (!params['vin']) {
          console.error('No hay vin en la ruta');
          return;
        }
        this.vinSearch = params['vin'] || '';
        this.nuevoRegistro();
      } else {
        if (params['vin']) {
          this.vinSearch = params['vin'] || '';
          if (this.vinSearch) {
            this.searchUnidadGeneralvin(this.vinSearch);
          }
        }
      }
    });
  }

  // -------------------------------------------------------------------------
  // GETTERS
  // -------------------------------------------------------------------------
  get incidencias(): FormArray {
    return this.unidadForm.get('incidencias') as FormArray;
  }

  // -------------------------------------------------------------------------
  // CARGA DE DATOS
  // -------------------------------------------------------------------------
  loadMonedas(): void {
    this.monedasService.getAllMonedas().subscribe({
      next: (data: Moneda[]) => {
        this.monedasOptions = data.map(moneda => ({
          label: `${moneda.nombre} (${moneda.simbolo})`,
          value: moneda.idMoneda
        }));
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar monedas',
          severity: 'error'
        });
      }
    });
  }

  loadSolicitudes(): void {
    this.solicitudService.getAllSolicitudes().subscribe({
      next: (data) => {
        if (data) {
          this.solicitudesOptions = data.map(solicitud => ({
            label: solicitud.idSolicitud + '',
            value: solicitud.idSolicitud
          }));
        } else {
          let h1: HttpErrorResponse = new HttpErrorResponse({ error: '', status: 404, statusText: 'Not Found' });
          this.handleError(h1, {
            defaultMessage: 'Error al cargar solicitudes',
            severity: 'error'
          });
          throw new Error('Error al cargar solicitudes');
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar solicitudes',
          severity: 'error'
        });
      }
    });
  }

  loadFacturas(): void {
    this.facturaService.getAll().subscribe({
      next: (data) => {
        if (data) {
          this.facturaOptions = data.map(factura => ({
            label: factura.idFactura,
            value: factura.idFactura
          }));
        } else {
          let h1: HttpErrorResponse = new HttpErrorResponse({ error: '', status: 404, statusText: 'Not Found' });
          this.handleError(h1, {
            defaultMessage: 'Error al cargar facturas',
            severity: 'error'
          });
          throw new Error('Error al cargar facturas');
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar facturas',
          severity: 'error'
        });
      }
    });
  }

  loadEmpaques(): void {
    this.empaqueService.getAll().subscribe({
      next: (data) => {
        if (data) {
          this.empaqueOptions = data.map(empaque => ({
            label: (empaque.nombreProveedor || 'Empaque') + ' ' + empaque.idEmpaque,
            value: empaque.idEmpaque
          }));
        } else {
          let h1: HttpErrorResponse = new HttpErrorResponse({ error: '', status: 404, statusText: 'Not Found' });
          this.handleError(h1, {
            defaultMessage: 'Error al cargar empaques',
            severity: 'error'
          });
          throw new Error('Error al cargar empaques');
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar empaques',
          severity: 'error'
        });
      }
    });
  }

  loadBillOfLandings(): void {
    this.billOfLandingService.getAll().subscribe({
      next: (data) => {
        if (data) {
          this.billOfLandingOptions = data.map(billOfLanding => ({
            label: billOfLanding.idBl,
            value: billOfLanding.idBl
          }));
        } else {
          let h1: HttpErrorResponse = new HttpErrorResponse({ error: '', status: 404, statusText: 'Not Found' });
          this.handleError(h1, {
            defaultMessage: 'Error al cargar bill of landings',
            severity: 'error'
          });
          throw new Error('Error al cargar bill of landings');
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar bill of landings',
          severity: 'error'
        });
      }
    });
  }

  loadTiposUnidad(): void {
    this.tiposUnidadService.getAllTiposUnidad().subscribe({
      next: (data) => {
        if (data) {
          this.tipoUnidadOptions = data.map(tipoUnidad => ({
            label: tipoUnidad.tipoUnidad,
            value: tipoUnidad.tipoUnidad
          }));
        } else {
          let h1: HttpErrorResponse = new HttpErrorResponse({ error: '', status: 404, statusText: 'Not Found' });
          this.handleError(h1, {
            defaultMessage: 'Error al cargar tipos de unidad',
            severity: 'error'
          });
          throw new Error('Error al cargar tipos de unidad');
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar tipos de unidad',
          severity: 'error'
        });
      }
    });
  }

  loadMaestroUnidades(): void {
    this.unidadService.getUnidades().subscribe({
      next: (data: Unidad[]) => {
        if (data) {
          this.unidades = data;
          this.vinsCargados = this.unidades.map(unidad => unidad.vin);
        } else {
          let h1: HttpErrorResponse = new HttpErrorResponse({ error: '', status: 404, statusText: 'Not Found' });
          this.handleError(h1, {
            defaultMessage: 'Error al cargar unidades',
            severity: 'error'
          });
          throw new Error('Error al cargar unidades');
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar unidades',
          severity: 'error'
        });
      }
    });
  }

  loadProformas(): void {
    this.proformaService.getAllProformas().subscribe({
      next: (data) => {
        if (data) {
          this.proformaOptions = data.map(proforma => ({
            label: proforma.idProforma,
            value: proforma.idProforma
          }));
        } else {
          let h1: HttpErrorResponse = new HttpErrorResponse({ error: '', status: 404, statusText: 'Not Found' });
          this.handleError(h1, {
            defaultMessage: 'Error al cargar proformas',
            severity: 'error'
          });
          throw new Error('Error al cargar proformas');
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar proformas',
          severity: 'error'
        });
      }
    });
  }

  loadCatalogo(): void {
    this.catalogoService.getAllItems().subscribe({
      next: (data: Catalogo[]) => {
        if (data) {
          this.catalogo = data.map(catalogo => ({
            label: catalogo.nombre,
            value: catalogo.idUnidad
          }));
        } else {
          let h1: HttpErrorResponse = new HttpErrorResponse({ error: '', status: 404, statusText: 'Not Found' });
          this.handleError(h1, {
            defaultMessage: 'Error al cargar catálogo',
            severity: 'error'
          });
          throw new Error('Error al cargar catálogo');
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar catálogo',
          severity: 'error'
        });
      }
    });
  }

  loadAgencias(): void {
    this.agenciasService.getAll().subscribe({
      next: (data: Agencia[]) => {
        if (data) {
          this.agencias = data.map(agencia => ({
            label: agencia.nombre,
            value: agencia.idAgencia
          }));
        } else {
          let h1: HttpErrorResponse = new HttpErrorResponse({ error: '', status: 404, statusText: 'Not Found' });
          this.handleError(h1, {
            defaultMessage: 'Error al cargar agencias',
            severity: 'error'
          });
          throw new Error('Error al cargar agencias');
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar agencias',
          severity: 'error'
        });
      }
    });
  }

  loadAcuerdos(): void {
    this.acuerdoService.getAll().subscribe({
      next: (data: Acuerdo[]) => {
        if (data) {
          this.acuerdos = data.map(acuerdo => ({
            label: acuerdo.descripcion,
            value: acuerdo.tipoAcuerdo
          }));
        } else {
          let h1: HttpErrorResponse = new HttpErrorResponse({ error: '', status: 404, statusText: 'Not Found' });
          this.handleError(h1, {
            defaultMessage: 'Error al cargar acuerdos',
            severity: 'error'
          });
          throw new Error('Error al cargar acuerdos');
        }
      },
      error: (error) => {
        this.handleError(error, {
          defaultMessage: 'Error al cargar acuerdos',
          severity: 'error'
        });
      }
    });
  }

  // -------------------------------------------------------------------------
  // BÚSQUEDAS
  // -------------------------------------------------------------------------
  searchVin(event: AutoCompleteCompleteEvent) {
    let filtered: string[] = [];
    let query = event.query;

    for (let unidad of this.unidades) {
      if (unidad.vin.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(unidad.vin);
      }
    }
    this.vinsCargados = filtered;
  }

  searchUnidadGeneral(event: any): void {
    const vin = event.query;
    this.searchUnidadGeneralvin(vin);
  }

  searchUnidadGeneralvin(pvin?: string): void {
    const vin = pvin ? pvin : this.vinSearch;

    this.unidadService.getUnidadesByVin(vin).subscribe({
      next: (unidadArray: Unidad[]) => {
        if (unidadArray && unidadArray.length > 0) {
          this.selectedUnidad = unidadArray[0];

          if (this.selectedUnidad.fechaEntrega) {
            const [year, month, day] = this.selectedUnidad.fechaEntrega.map(Number);
            this.selectedUnidad.fechaEntregaDate = new Date(year, month - 1, day);
            this.selectedUnidad.fechaEntrega = this.selectedUnidad.fechaEntregaDate;
          }

          if (this.selectedUnidad.fechaAcuerdo) {
            const [year, month, day] = this.selectedUnidad.fechaAcuerdo.map(Number);
            this.selectedUnidad.fechaAcuerdoDate = new Date(year, month - 1, day);
            this.selectedUnidad.fechaAcuerdo = this.selectedUnidad.fechaAcuerdoDate;
          }

          this.incidencias.clear();

          this.inspeccionPreentregaService.getInspeccionByVin(this.selectedUnidad.vin).subscribe({
            next: (inspeccion: InspeccionPreentrega) => {
              this.inspeccion = inspeccion;
              this.inspeccionDetalleService.getDetalleByVin(this.selectedUnidad!.vin).subscribe({
                next: (inspeccionDetalle: InspeccionDetalle[]) => {
                  this.inspeccionDetalle = inspeccionDetalle;
                  this.inspeccionDetalleOri = inspeccionDetalle;

                  inspeccionDetalle.forEach(detalle => {
                    const incidenciaGroup = this.fb.group({
                      tipo: [{ value: detalle.tipoPunto, disabled: true }],
                      descripcion: [{ value: detalle.descripcion, disabled: true }],
                      accion: [{ value: detalle.accion, disabled: true }],
                      valor: [{ value: detalle.valor, disabled: true }],
                      moneda: [{ value: detalle.idMoneda, disabled: true }]
                    });
                    this.incidencias.push(incidenciaGroup);
                  });
                },
                error: (error) => {
                  this.handleError(error);
                }
              });
            },
            error: (error) => {
              this.handleError(error);
            }
          });

          this.unidadForm.patchValue(this.selectedUnidad);
          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();
          window.scrollTo(0, 0);
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unidad no encontrada' });
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  // -------------------------------------------------------------------------
  // MODO CREAR / EDITAR
  // -------------------------------------------------------------------------
  nuevoRegistro(): void {
    this.unidadForm.reset();
    this.isEditing = false;
    this.isCreating = true;
    this.selectedUnidad = null;
    this.enableForm();

    // Preasigna valores
    this.unidadForm.get('vin')?.setValue(this.vinSearch);
    this.unidadForm.get('vin')?.updateValueAndValidity();
    this.unidadForm.get('tipoUnidad')?.setValue('VHO');
    this.unidadForm.get('tipoUnidad')?.updateValueAndValidity();
  }

  editarUnidad(): void {
    this.isEditing = true;
    this.isCreating = false;
    this.enableForm();
  }

  submitForm(): void {
    this.isCreating ? this.createUnidad() : this.updateUnidad();
  }

  // -------------------------------------------------------------------------
  // INCIDENCIAS
  // -------------------------------------------------------------------------
  addIncidencia(): void {
    const incidenciaGroup = this.fb.group({
      tipo: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      descripcion: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      accion: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      valor: [{ value: 0, disabled: !this.isEditing && !this.isCreating }],
      moneda: [{ value: '', disabled: !this.isEditing && !this.isCreating }]
    });
    this.incidencias.push(incidenciaGroup);
  }

  removeIncidencia(index: number): void {
    this.incidencias.removeAt(index);
  }

  // -------------------------------------------------------------------------
  // HANDLERS DE DROPDOWNS
  // -------------------------------------------------------------------------
  handleSolicitudChange(): void {
    if (!this.unidadForm.get('idSolicitud')?.value) {
      this.proformaOptions = [];
      this.unidadForm.get('idProforma')?.reset();
      this.billOfLandingOptions = [];
      this.unidadForm.get('idBl')?.reset();
      this.facturaOptions = [];
      this.unidadForm.get('idFactura')?.reset();
      this.empaqueOptions = [];
      this.unidadForm.get('idEmpaque')?.reset();

      this.loadProformas();
      this.loadEmpaques();
      this.loadBillOfLandings();
      this.loadFacturas();
      return;
    }
    this.loadProformasByIdSolicitud();
  }

  loadProformasByIdSolicitud(): void {
    const idSolicitud = this.unidadForm.get('idSolicitud')?.value;
    if (!idSolicitud) {
      return;
    }

    this.proformaService.getProformaBsyId(idSolicitud).subscribe(
      (data) => {
        if (!data || data.length === 0) {
          this.messageService.add({ severity: 'info', summary: 'Sin datos', detail: 'No hay proformas para esta solicitud' });
          this.proformaOptions = [];
          return;
        }
        this.proformaOptions = data.map(proforma => ({
          label: proforma.idProforma,
          value: proforma.idProforma
        }));
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  handleEmpaqueChange(): void {
    if (!this.unidadForm.get('idEmpaque')?.value) {
      this.billOfLandingOptions = [];
      this.unidadForm.get('idBl')?.reset();
      this.facturaOptions = [];
      this.unidadForm.get('idFactura')?.reset();

      this.loadBillOfLandings();
      this.loadFacturas();
      return;
    }
    this.loadBillOfLandingsByEmpaqueId();
  }

  loadBillOfLandingsByEmpaqueId(): void {
    const idEmpaque = this.unidadForm.get('idEmpaque')?.value;
    if (!idEmpaque) {
      return;
    }

    this.billOfLandingService.getBillOfLandingsByEmpaqueId(idEmpaque).subscribe(
      (data) => {
        if (!data || data.length === 0) {
          this.messageService.add({ severity: 'info', summary: 'Sin datos', detail: 'No hay BL para este empaque' });
          this.billOfLandingOptions = [];
          return;
        }
        this.billOfLandingOptions = data.map(bill => ({
          label: bill.idBl,
          value: bill.idBl
        }));
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  handleBillOfLandingChange(): void {
    if (!this.unidadForm.get('idBl')?.value) {
      this.facturaOptions = [];
      return;
    }
    this.loadFacturaByBl();
  }

  loadFacturaByBl(): void {
    const idBl = this.unidadForm.get('idBl')?.value;
    if (!idBl) {
      return;
    }

    this.facturaService.getFacturaComercialByBL(idBl).subscribe(
      (data) => {
        if (!data || data.length === 0) {
          this.messageService.add({ severity: 'info', summary: 'Sin datos', detail: 'No hay facturas disponibles para este BL' });
          this.facturaOptions = [];
          return;
        }
        this.facturaOptions = data.map(factura => ({
          label: factura.idFactura,
          value: factura.idFactura
        }));
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  // -------------------------------------------------------------------------
  // CREAR
  // -------------------------------------------------------------------------
  isNotValidAndCompleteForm(): boolean {
    let faltanCampos = false;

    Object.keys(this.unidadForm.controls).forEach(key => {
      const controlErrors = this.unidadForm.get(key)?.errors;
      if (controlErrors) {
        this.unidadForm.get(key)?.markAsDirty();
        this.unidadForm.get(key)?.markAsTouched();
        faltanCampos = true;
      }
    });

    this.incidencias.controls.forEach((incidencia, index) => {
      Object.keys((incidencia as FormGroup).controls).forEach(key => {
        const controlErrors = (incidencia as FormGroup).get(key)?.errors;
        if (controlErrors) {
          faltanCampos = true;
        }
      });
    });

    if (faltanCampos) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Formulario inválido: Complete los campos obligatorios'
      });
    }
    return faltanCampos;
  }

  createUnidad(): void {
    const creatingVin = this.unidadForm.get('vin')?.value;
    if (this.isNotValidAndCompleteForm()) {
      return;
    }

    const unidadData = this.unidadForm.value;

    // *** CAMBIO: Quitamos tipoProveedor e idProveedor del request
    const inspeccionData = {
      vin: unidadData.vin,
      tipoUnidad: unidadData.tipoUnidad,
      idUnidad: unidadData.idUnidad,
      nroMotor: unidadData.nroMotor,
      idPreentrega: 1,
      fechaEntrega: unidadData.fechaEntrega,
      tipoProveedor: unidadData.tipoProveedor,
      idProveedor: unidadData.idProveedor
    };

    this.unidadService
      .createUnidad(unidadData)
      .pipe(
        switchMap(() =>
          this.inspeccionPreentregaService.createInspeccion(inspeccionData)
        ),
        switchMap(() => {
          const incidenciaObservables = this.incidencias.controls.map(
            (incidencia, index) =>
              this.inspeccionDetalleService.createDetalle({
                vin: unidadData.vin,
                tipoUnidad: unidadData.tipoUnidad,
                idUnidad: unidadData.idUnidad,
                nroMotor: unidadData.nroMotor,
                idPreentrega: 1,
                nroOrden: index + 1,
                tipoPunto: (incidencia as FormGroup).get('tipo')?.value,
                descripcion: (incidencia as FormGroup).get('descripcion')?.value,
                accion: (incidencia as FormGroup).get('accion')?.value,
                valor: parseFloat((incidencia as FormGroup).get('valor')?.value),
                idMoneda: (incidencia as FormGroup).get('moneda')?.value,
              })
          );

          return incidenciaObservables.length > 0
            ? forkJoin(incidenciaObservables)
            : of(null);
        }),
        switchMap(() =>
          this.unidadService.regenerarQr(
            unidadData.vin,
            unidadData.tipoUnidad,
            unidadData.idUnidad,
            unidadData.nroMotor
          )
        )
      )
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Unidad creada correctamente'
          });
          this.loadMaestroUnidades();
          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();
          this.searchUnidadGeneralvin(creatingVin);
        },
        error: (error) => {
          const errorMessage =
            error.status === 400 && error?.error?.details
              ? `Error al crear la unidad: ${error.error.details}`
              : 'Error al crear la unidad';
          this.handleError(error, { defaultMessage: errorMessage });
        }
      });
  }

  // -------------------------------------------------------------------------
  // DESCARGAR QR
  // -------------------------------------------------------------------------
  downloadQR(): void {
    if (this.selectedUnidad) {
      this.unidadService
        .regenerarQr(
          this.unidadForm.get('vin')?.value,
          this.unidadForm.get('tipoUnidad')?.value,
          this.unidadForm.get('idUnidad')?.value,
          this.unidadForm.get('nroMotor')?.value
        )
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'QR regenerado correctamente'
            });
            this.unidadService
              .obtenerImagenQr(
                this.selectedUnidad!.vin,
                this.selectedUnidad!.tipoUnidad,
                this.selectedUnidad!.idUnidad,
                this.selectedUnidad!.nroMotor
              )
              .subscribe({
                next: (qrBlob: Blob) => {
                  const url = window.URL.createObjectURL(qrBlob);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = 'qr.png';
                  a.click();
                  window.URL.revokeObjectURL(url);
                },
                error: (error) => {
                  this.handleError(error);
                }
              });
          },
          error: (error) => {
            this.handleError(error);
          }
        });
    }
  }

  // -------------------------------------------------------------------------
  // ACTUALIZAR
  // -------------------------------------------------------------------------
  updateUnidad(): void {
    if (this.isNotValidAndCompleteForm() || !this.selectedUnidad || !this.isEditing) {
      return;
    }

    const unidadData = this.unidadForm.value;
    const { vin, tipoUnidad, idUnidad, nroMotor } = this.selectedUnidad;

    this.unidadService.updateUnidad({ vin, tipoUnidad, idUnidad, nroMotor }, unidadData)
      .pipe(
        switchMap(() => {
          if (this.inspeccionDetalleOri.length === 0) {
            return of(null);
          }
          return forkJoin(
            this.inspeccionDetalleOri.map(detalle =>
              this.inspeccionDetalleService.deleteDetalle(
                vin, tipoUnidad, idUnidad, nroMotor, detalle.idPreentrega, detalle.nroOrden
              ).pipe(
                catchError(error => {
                  console.warn('Error al eliminar detalle:', detalle, error);
                  return of(null);
                })
              )
            )
          );
        }),
        switchMap(() => {
          if (this.incidencias.controls.length === 0) {
            return of(null);
          }

          const detallesNuevos = this.incidencias.controls.map((incidencia, index) => ({
            vin,
            tipoUnidad,
            idUnidad,
            nroMotor,
            idPreentrega: 1,
            nroOrden: index + 1,
            tipoPunto: (incidencia as FormGroup).get('tipo')?.value,
            descripcion: (incidencia as FormGroup).get('descripcion')?.value,
            accion: (incidencia as FormGroup).get('accion')?.value,
            valor: parseFloat((incidencia as FormGroup).get('valor')?.value),
            idMoneda: (incidencia as FormGroup).get('moneda')?.value,
          }));

          return forkJoin(
            detallesNuevos.map(detalle =>
              this.inspeccionDetalleService.createDetalle(detalle)
            )
          );
        }),
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Unidad actualizada correctamente'
          });
          this.resetFormState();
        })
      )
      .subscribe({
        complete: () => {
          this.loadMaestroUnidades();
          this.searchUnidadGeneral(vin);
        },
        error: (error) => {
          const errorMessage =
            error.status === 400 && error.error?.details
              ? `Error: ${error.error.details}`
              : 'Error en el proceso de actualización';

          this.handleError(error, {
            defaultMessage: errorMessage,
            severity: 'error'
          });
        }
      });
  }

  private resetFormState(): void {
    this.isEditing = false;
    this.isCreating = false;
    this.disableForm();
  }

  // -------------------------------------------------------------------------
  // ELIMINAR
  // -------------------------------------------------------------------------
  deleteUnidad(dialog?: boolean): void {
    if (!this.selectedUnidad) {
      return;
    }

    this.confirmationService.confirm({
      message: '¿Está seguro de eliminar la unidad?',
      accept: () => {
        this.eliminarDetallesDeInspeccion()
          .pipe(
            catchError(error => {
              if (error.status === 404) {
                return of(void 0);
              }
              throw error;
            }),
            switchMap(() => this.eliminarInspeccionPreentrega()),
            catchError(error => {
              if (error.status === 404) {
                return of(void 0);
              }
              throw error;
            }),
            switchMap(() => this.eliminarUnidad()),
            catchError((error) => {
              this.handleError(error, {
                defaultMessage: `Error en el proceso de eliminación: ${error.message}`,
                severity: 'error'
              });
              throw error;
            })
          )
          .subscribe({
            next: () => {
              this.finalizarEliminacion();
            },
            error: (error) => {
              this.handleError(error, {
                defaultMessage: 'Error inesperado durante el flujo de eliminación:',
                severity: 'error'
              });
            }
          });
      }
    });
  }

  private eliminarDetallesDeInspeccion(): Observable<void> {
    if (this.inspeccionDetalle.length === 0) {
      return of(void 0);
    }

    const deleteObservables = this.inspeccionDetalle.map(detalle => {
      return this.inspeccionDetalleService
        .deleteDetalle(
          this.selectedUnidad!.vin,
          this.selectedUnidad!.tipoUnidad,
          this.selectedUnidad!.idUnidad,
          this.selectedUnidad!.nroMotor,
          detalle.idPreentrega,
          detalle.nroOrden
        )
        .pipe(
          catchError(error => {
            this.handleError(error, { defaultMessage: 'Error al eliminar detalle de inspección preentrega', severity: 'error' });
            return of(null);
          })
        );
    });

    return forkJoin(deleteObservables).pipe(map(() => void 0));
  }

  private eliminarInspeccionPreentrega(): Observable<void> {
    return this.inspeccionPreentregaService
      .deleteInspeccion(
        this.selectedUnidad!.vin,
        this.selectedUnidad!.tipoUnidad,
        this.selectedUnidad!.idUnidad,
        this.selectedUnidad!.nroMotor,
        1
      )
      .pipe(
        catchError(error => {
          if (error.status === 404) {
            return of(void 0);
          }
          this.handleError(error, { defaultMessage: 'Error al eliminar inspección preentrega', severity: 'error' });
          return of(null);
        }),
        map(() => void 0)
      );
  }

  private eliminarUnidad(): Observable<void> {
    return this.unidadService
      .deleteUnidad({
        vin: this.selectedUnidad!.vin,
        tipoUnidad: this.selectedUnidad!.tipoUnidad,
        idUnidad: this.selectedUnidad!.idUnidad,
        nroMotor: this.selectedUnidad!.nroMotor
      })
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Unidad eliminada correctamente'
          });
        }),
        catchError(error => {
          this.handleError(error, {
            defaultMessage: 'Error al eliminar la unidad',
            severity: 'error'
          });
          return of(null);
        }),
        map(() => void 0)
      );
  }

  private finalizarEliminacion(): void {
    this.loadMaestroUnidades();
    this.unidadForm.reset();
    this.isEditing = false;
    this.selectedUnidad = null;
    this.disableForm();
    this.incidencias.clear();
    this.vinSearch = '';
  }

  // -------------------------------------------------------------------------
  // BOTONES Y UTILIDADES
  // -------------------------------------------------------------------------
  callCreateUnidadVendida(): void {
    this.router.navigate(['/maestro-unidades-vendidas/vin/', this.selectedUnidad?.vin]);
  }

  cancelEdit(): void {
    this.unidadForm.reset();
    while (this.incidencias.length !== 0) {
      this.incidencias.removeAt(0);
    }
    this.isEditing = false;
    this.isCreating = false;
    this.selectedUnidad = null;
    this.disableForm();
  }

  enableForm(): void {
    Object.keys(this.unidadForm.controls).forEach(controlName => {
      const control = this.unidadForm.get(controlName);
      control?.enable();
      control?.updateValueAndValidity();
    });
    this.incidencias.controls.forEach(incidencia => {
      (incidencia as FormGroup).enable();
      (incidencia as FormGroup).updateValueAndValidity();
    });
  }

  disableForm(): void {
    Object.keys(this.unidadForm.controls).forEach(controlName => {
      this.unidadForm.get(controlName)?.disable();
    });
    this.incidencias.controls.forEach(incidencia => {
      (incidencia as FormGroup).disable();
    });
  }
}
