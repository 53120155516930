<!-- catalogo.component.html -->
<div class="card p-4">
  <p-toast></p-toast>
  <p-confirmDialog></p-confirmDialog>

  <div class="flex justify-between items-center mb-4">
      <h2 class="text-2xl font-bold">Gestión de Catálogo</h2>
      <button pButton label="Nuevo Catálogo" icon="pi pi-plus" class="p-button-primary" (click)="showDialog()"></button>
  </div>

  <!-- [globalFilterFields]="['nombre', 'nombreChino', 'nombreIngles', 'tipoUnidad']" -->
  <p-table
      #dt
      [value]="catalogs"
      [rows]="10"
      [paginator]="true"
      [tableStyle]="{'min-width': '75rem'}"
      [loading]="loading"
      [rowHover]="true"
      dataKey="idUnidad"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
      [rowsPerPageOptions]="[10,25,50]">

      <!-- <ng-template pTemplate="caption">
          <div class="flex justify-between items-center">
              <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" [(ngModel)]="globalFilter" placeholder="Buscar..." class="p-inputtext-sm" />
              </span>
          </div>
      </ng-template> -->

      <ng-template pTemplate="header">
          <tr>
              <th pSortableColumn="tipoUnidad">Tipo Unidad <p-sortIcon field="tipoUnidad"></p-sortIcon></th>
              <th pSortableColumn="idUnidad">ID Unidad <p-sortIcon field="idUnidad"></p-sortIcon></th>
              <th pSortableColumn="nombre">Nombre <p-sortIcon field="nombre"></p-sortIcon></th>
              <th pSortableColumn="nombreChino">Nombre Chino <p-sortIcon field="nombreChino"></p-sortIcon></th>
              <th pSortableColumn="nombreIngles">Nombre Inglés <p-sortIcon field="nombreIngles"></p-sortIcon></th>
              <th pSortableColumn="unidadesEmbalaje">Unidades Embalaje <p-sortIcon field="unidadesEmbalaje"></p-sortIcon></th>
              <th pSortableColumn="stockMinimoRecomendado">Stock Mínimo <p-sortIcon field="stockMinimoRecomendado"></p-sortIcon></th>
              <th pSortableColumn="valorRef">Valor Ref. <p-sortIcon field="valorRef"></p-sortIcon></th>
              <th>Acciones</th>
          </tr>
          <tr>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'tipoUnidad', 'contains')"
                         placeholder="Buscar por tipo" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="number" (input)="dt.filter($any($event.target)?.value, 'idUnidad', 'contains')"
                         placeholder="Buscar por ID Unidad" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'nombre', 'contains')"
                         placeholder="Buscar por nombre" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'nombreChino', 'contains')"
                         placeholder="Buscar por nombre chino" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'nombreIngles', 'contains')"
                         placeholder="Buscar por nombre inglés" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="number" (input)="dt.filter($any($event.target)?.value, 'unidadesEmbalaje', 'contains')"
                         placeholder="Buscar por unidades" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="number" (input)="dt.filter($any($event.target)?.value, 'stockMinimoRecomendado', 'contains')"
                         placeholder="Buscar por stock" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="number" (input)="dt.filter($any($event.target)?.value, 'valorRef', 'contains')"
                         placeholder="Buscar por valor" class="p-column-filter w-full">
              </th>
              <th></th>
          </tr>
      </ng-template>

      <ng-template pTemplate="body" let-catalog>
          <tr>
              <td>{{catalog.tipoUnidad}}</td>
              <td>{{catalog.idUnidad}}</td>
              <td>{{catalog.nombre}}</td>
              <td>{{catalog.nombreChino}}</td>
              <td>{{catalog.nombreIngles}}</td>
              <td>{{catalog.unidadesEmbalaje}}</td>
              <td>{{catalog.stockMinimoRecomendado}}</td>
              <td>{{catalog.valorRef}} {{catalog.simboloMoneda}}</td>
              <td>
                  <div class="flex gap-2">
                      <button pButton icon="pi pi-pencil" class="p-button-rounded p-button-warning p-button-sm"
                              (click)="showDialog(catalog)"></button>
                      <button pButton icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-sm"
                              (click)="deleteCatalog(catalog)"></button>
                  </div>
              </td>
          </tr>
      </ng-template>
  </p-table>

  <p-dialog [(visible)]="dialogVisible" [style]="{width: '50vw'}" [modal]="true"
            [header]="catalog.idUnidad ? 'Editar Catálogo' : 'Nuevo Catálogo'">
      <div class="grid grid-cols-2 gap-4">
          <div class="col-span-1">
              <label class="block mb-2">Tipo Unidad</label>
              <!-- <input pInputText  class="w-full" /> -->

            <p-dropdown id="tipoUnidad" [(ngModel)]="catalog.tipoUnidad" [options]="tipoUnidadOptions"
              class="w-full p-dropdown-sm" [filter]="true"
              filterPlaceholder="Buscar Tipo de unidad"
              [style]="{ height: '2.6rem', width: '100%' }"
            >
            </p-dropdown>
          </div>
          <div class="col-span-1">
            <label class="block mb-2">ID Unidad</label>
            <input pInputText [(ngModel)]="catalog.idUnidad" class="w-full" />
        </div>
          <div class="col-span-1">
              <label class="block mb-2">Nombre</label>
              <input pInputText [(ngModel)]="catalog.nombre" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Nombre Chino</label>
              <input pInputText [(ngModel)]="catalog.nombreChino" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Nombre Inglés</label>
              <input pInputText [(ngModel)]="catalog.nombreIngles" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Unidades Embalaje</label>
              <input pInputText type="number" [(ngModel)]="catalog.unidadesEmbalaje" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Stock Mínimo</label>
              <input pInputText type="number" [(ngModel)]="catalog.stockMinimoRecomendado" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Valor Referencia</label>
              <input pInputText type="number" [(ngModel)]="catalog.valorRef" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Moneda</label>
              <!-- <input pInputText [(ngModel)]="catalog.idMoneda" class="w-full" /> -->

              <p-dropdown id="moneda"  [(ngModel)]="catalog.idMoneda" class="w-full p-dropdown-sm rounded-lg" [showClear]="false" [filter]="false"
                appendTo="body"
                  filterPlaceholder="Buscar Moneda" [options]="monedasOptions"
                  [style]="{ height: '2.6rem', width: '100%' }">
                </p-dropdown>
          </div>
          <div class="col-span-2">
              <label class="block mb-2">Fecha Referencia</label>
              <p-calendar [(ngModel)]="catalog.fechaRef" [showIcon]="true" class="w-full"></p-calendar>
          </div>
      </div>

      <ng-template pTemplate="footer">
          <button pButton label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="dialogVisible = false"></button>
          <button pButton label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveCatalog()"></button>
      </ng-template>
  </p-dialog>
</div>
