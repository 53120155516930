// Service: TitularService
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Titular } from '../models/titular.model';
import { FechaService } from './fecha.service';


@Injectable({
  providedIn: 'root',
})
export class TitularService {
  private apiUrl = `${environment.baseUrl}/api/titulares`;

  constructor(private http: HttpClient, private fechaService: FechaService) {}

  // Obtener todos los titulares
  getAllTitulares(): Observable<Titular[]> {
    return this.http.get<Titular[]>(this.apiUrl);
  }

  // // Obtener un titular por ID
  // getTitularById(id: number): Observable<Titular> {
  //   return this.http.get<Titular>(`${this.apiUrl}/${id}`);
  // }

  // Crear un nuevo titular
  createTitular(titular: Titular): Observable<Titular> {
    // Convertir la fecha interna (Date) al formato esperado por el backend
    const titularBackend = {
      ...titular,
      fechaTitularidad: this.fechaService.toBackendDate(titular.fechaTitularidadDate!), // Convertir Date a [number, number, number]
    };

    return this.http.post<Titular>(this.apiUrl, titularBackend);
  }

  // Actualizar un titular existente
  // updateTitular(id: number, titular: Titular): Observable<Titular> {
  //   return this.http.put<Titular>(`${this.apiUrl}/${id}`, titular);
  // }

  // Eliminar titulares by dominio
  deleteTitularesByDominio(dominio: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/dominio/${dominio}`);
  }

  getTitularesByDominio(parametro: string): Observable<Titular[]> {
    return this.http.get<Titular[]>(`${this.apiUrl}/dominio/${parametro}`).pipe(
      map((titulares) =>
        titulares.map((titular) => ({
          ...titular,
          fechaTitularidadDate: this.fechaService.fromBackendDate(titular.fechaTitularidad), // Convertir a Date
        }))
      )
    );
  }


}
