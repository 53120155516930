// acuerdos.component.ts
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AcuerdoService } from '../../services/acuerdos.service';
import { Acuerdo } from '../../models/acuerdo.model';

@Component({
  selector: 'app-acuerdos',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    DialogModule,
    ToastModule,
    ConfirmDialogModule
  ],
  templateUrl: './acuerdos.component.html',
  providers: [MessageService, ConfirmationService]
})
export class AcuerdosComponent implements OnInit {
  acuerdos: Acuerdo[] = [];
  acuerdo: Acuerdo = this.getEmptyAcuerdo();
  dialogVisible = false;
  loading = false;
  globalFilter: string = '';
  isNew: boolean = true;

  constructor(
    private acuerdosService: AcuerdoService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.loadAcuerdos();
  }

  getEmptyAcuerdo(): Acuerdo {
    return {
      tipoAcuerdo: '',
      descripcion: ''
    };
  }

  loadAcuerdos() {
    this.loading = true;
    this.acuerdosService.getAll().subscribe({
      next: (data) => {
        this.acuerdos = data;
        this.loading = false;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al cargar los acuerdos'
        });
        this.loading = false;
      }
    });
  }

  showDialog(acuerdo?: Acuerdo) {
    if (acuerdo) {
      this.acuerdo = { ...acuerdo };
      this.isNew = false;
    } else {
      this.acuerdo = this.getEmptyAcuerdo();
      this.isNew = true;
    }
    this.dialogVisible = true;
  }

  saveAcuerdo() {
    if (this.isNew) {
      this.acuerdosService.create(this.acuerdo).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Acuerdo creado correctamente'
          });
          this.loadAcuerdos();
          this.dialogVisible = false;
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al crear el acuerdo'
          });
        }
      });
    } else {
      this.acuerdosService.update(this.acuerdo.tipoAcuerdo, this.acuerdo).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Acuerdo actualizado correctamente'
          });
          this.loadAcuerdos();
          this.dialogVisible = false;
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al actualizar el acuerdo' + error.message
          });
        }
      });
    }
  }

  deleteAcuerdo(acuerdo: Acuerdo) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar este acuerdo?',
      accept: () => {
        this.acuerdosService.delete(acuerdo.tipoAcuerdo).subscribe({
          next: (): void => {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Acuerdo eliminado correctamente'
            });
            this.loadAcuerdos();
          },
          error: (error: any): void => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al eliminar el acuerdo' + error.message
            });
          }
        });
      }
    });
  }
}
