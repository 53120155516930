import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const roles = route.data['roles'] as Array<string>;

    if (!roles) {
      throw new Error('Roles are not setup for this route');
      return false;
    }

    // console.log('Requesting Roles:', roles);
    // console.log('Requesting route:', route);

    if (roles && this.authService.hasAnyRole(roles)) {
      return true;
    }
    this.router.navigate(['/home']); // Redirige si no tiene el rol
    return false;
  }
}
