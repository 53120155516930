import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServicioGarantia } from '../models/servicios-garantia.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServiciosGarantiaService {
  private baseUrl = `${environment.baseUrl}/api/servicios-garantia`;

  constructor(private http: HttpClient) {}

  // Obtener todos los servicios de garantía
  getAllServiciosGarantia(): Observable<ServicioGarantia[]> {
    return this.http.get<ServicioGarantia[]>(this.baseUrl);
  }

  // Obtener un servicio de garantía por ID compuesto
  getServicioGarantiaById(
    tipoUnidad: string,
    idUnidad: number,
    idServicio: number
  ): Observable<ServicioGarantia> {
    const url = `${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idServicio}`;
    return this.http.get<ServicioGarantia>(url);
  }


  getAll(): Observable<ServicioGarantia[]> {
    return this.http.get<ServicioGarantia[]>(this.baseUrl);
  }

  getById(tipoUnidad: string, idUnidad: number, idServicio: number): Observable<ServicioGarantia> {
    return this.http.get<ServicioGarantia>(`${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idServicio}`);
  }

  getByTipoUnidad(tipoUnidad: string): Observable<ServicioGarantia[]> {
    return this.http.get<ServicioGarantia[]>(`${this.baseUrl}/tipo/${tipoUnidad}`);
  }

  getByCatalogo(tipoUnidad: string, idUnidad: number): Observable<ServicioGarantia[]> {
    return this.http.get<ServicioGarantia[]>(`${this.baseUrl}/catalogo/${tipoUnidad}/${idUnidad}`);
  }

  create(servicio: ServicioGarantia): Observable<ServicioGarantia> {
    return this.http.post<ServicioGarantia>(this.baseUrl, servicio);
  }

  update(tipoUnidad: string, idUnidad: number, idServicio: number, servicio: ServicioGarantia): Observable<ServicioGarantia> {
    return this.http.put<ServicioGarantia>(`${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idServicio}`, servicio);
  }

  delete(tipoUnidad: string, idUnidad: number, idServicio: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${tipoUnidad}/${idUnidad}/${idServicio}`);
  }

}
