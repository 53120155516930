// tipos-unidad.component.ts
import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TiposUnidadService } from '../../services/tipos-unidad.service';
import { AuthService } from '../../services/auth.service';
import { TiposUnidad } from '../../models/tipos-unidad.model';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-tipos-unidad',
  templateUrl: './tipos-unidad.component.html',
  providers: [MessageService, ConfirmationService]
})
export class TiposUnidadComponent extends BaseComponent  implements OnInit {
  tiposUnidad: TiposUnidad[] = [];
  tipoUnidad: TiposUnidad = this.getEmptyTipoUnidad();
  dialogVisible = false;
  loading = false;
  globalFilter: string = '';
  isNew: boolean = true;

  constructor(
    private tiposUnidadService: TiposUnidadService,
    private authService: AuthService,
    private confirmationService: ConfirmationService
  ) { super(); }

  ngOnInit() {
    this.loadTiposUnidad();
  }

  getEmptyTipoUnidad(): TiposUnidad {
    return {
      createdBy: '',
      updatedBy: null,
      tipoUnidad: '',
      descripcion: ''
    };
  }

  loadTiposUnidad() {
    this.loading = true;
    this.tiposUnidadService.getAllTiposUnidad().subscribe({
      next: (data) => {
        this.tiposUnidad = data;
        this.loading = false;
      },
      error: (error) => {
        this.handleError(error);
        this.loading = false;
      }
    });
  }

  showDialog(tipoUnidad?: TiposUnidad) {
    if (tipoUnidad) {
      this.tipoUnidad = { ...tipoUnidad };
      this.isNew = false;
    } else {
      this.tipoUnidad = this.getEmptyTipoUnidad();
      this.isNew = true;
    }
    this.dialogVisible = true;
  }

  saveTipoUnidad() {

    this.tipoUnidad.updatedBy = this.authService.getUserEmail();
    this.tipoUnidad.createdBy = this.authService.getUserEmail();
    console.log('Tipo de unidad:', this.tipoUnidad);

    const operation = this.isNew
      ? this.tiposUnidadService.createTipoUnidad(this.tipoUnidad)
      : this.tiposUnidadService.updateTipoUnidad(this.tipoUnidad);

    operation.subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Éxito',
          detail: `Tipo de unidad ${this.isNew ? 'creado' : 'actualizado'} correctamente`
        });
        this.loadTiposUnidad();
        this.dialogVisible = false;
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  deleteTipoUnidad(tipoUnidad: TiposUnidad) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar este tipo de unidad?',
      accept: () => {
        this.tiposUnidadService.deleteTipoUnidad(tipoUnidad.tipoUnidad).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Tipo de unidad eliminado correctamente'
            });
            this.loadTiposUnidad();
          },
          error: (error) => {
            this.handleError(error);
          }
        });
      }
    });
  }
}
