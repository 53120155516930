// proveedores.component.ts
import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ProveedoresService } from '../../services/proveedores.service';
import { Proveedor } from '../../models/proveedor.model';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  providers: [ConfirmationService]
})
export class ProveedoresComponent extends BaseComponent implements OnInit {
  proveedores: Proveedor[] = [];
  proveedor: Proveedor = this.getEmptyProveedor();
  dialogVisible = false;
  loading = false;
  globalFilter: string = '';
  isNew: boolean = true;

  constructor(
    private proveedoresService: ProveedoresService,
    private confirmationService: ConfirmationService
  ) { super(); }

  ngOnInit() {
    this.loadProveedores();
  }

  getEmptyProveedor(): Proveedor {
    return {
      tipoProveedor: '',
      idProveedor: 0,
      nombre: '',
      cuitCuil: '',
      movil: null,
      correo: null,
      calle: null,
      numero: null,
      provincia: null,
      localidad: null,
      codPostal: null,
      ponderacion: null,
      comentarios: null
    };
  }

  loadProveedores() {
    this.loading = true;
    this.proveedoresService.getAll().subscribe({
      next: (data) => {
        this.proveedores = data;
        this.loading = false;
      },
      error: (error) => {
        this.handleError(error);
        this.loading = false;
      }
    });
  }

  showDialog(proveedor?: Proveedor) {
    if (proveedor) {
      this.proveedor = { ...proveedor };
      this.isNew = false;
    } else {
      this.proveedor = this.getEmptyProveedor();
      this.isNew = true;
    }
    this.dialogVisible = true;
  }

  saveProveedor() {
    if (this.isNew) {
      this.proveedoresService.create(this.proveedor).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Proveedor creado correctamente'
          });
          this.loadProveedores();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    } else {
      this.proveedoresService.update(this.proveedor.tipoProveedor, this.proveedor.idProveedor, this.proveedor).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Proveedor actualizado correctamente'
          });
          this.loadProveedores();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    }
  }

  deleteProveedor(proveedor: Proveedor) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar este proveedor?',
      accept: () => {
        this.proveedoresService.delete(proveedor.tipoProveedor, proveedor.idProveedor).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Proveedor eliminado correctamente'
            });
            this.loadProveedores();
          },
          error: (error) => {
            this.handleError(error);
          }
        });
      }
    });
  }
}
