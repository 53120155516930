<!-- servicios-garantia.component.html -->
<div class="card p-4">
  <p-toast></p-toast>
  <p-confirmDialog></p-confirmDialog>

  <div class="flex justify-between items-center mb-4">
      <h2 class="text-2xl font-bold">Gestión de Servicios de Garantía</h2>
      <button pButton label="Nuevo Servicio" icon="pi pi-plus" class="p-button-primary" (click)="showDialog()"></button>
  </div>

  <p-table
      #dt
      [value]="servicios"
      [rows]="10"
      [paginator]="true"
      [tableStyle]="{'min-width': '75rem'}"
      [loading]="loading"
      [rowHover]="true"
      dataKey="idServicio"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
      [rowsPerPageOptions]="[10,25,50]">

      <ng-template pTemplate="header">
          <tr>
              <th pSortableColumn="tipoUnidad">Tipo Unidad <p-sortIcon field="tipoUnidad"></p-sortIcon></th>
              <th pSortableColumn="idUnidad">ID Unidad <p-sortIcon field="idUnidad"></p-sortIcon></th>
              <th pSortableColumn="idServicio">ID Servicio <p-sortIcon field="idServicio"></p-sortIcon></th>
              <th pSortableColumn="descripcion">Descripción <p-sortIcon field="descripcion"></p-sortIcon></th>
              <th pSortableColumn="valorRef">Valor Ref. <p-sortIcon field="valorRef"></p-sortIcon></th>
              <th pSortableColumn="tiempoRef">Tiempo Ref. <p-sortIcon field="tiempoRef"></p-sortIcon></th>
              <th>Acciones</th>
          </tr>
          <tr>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'tipoUnidad', 'contains')"
                         placeholder="Buscar por tipo" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'idUnidad', 'contains')"
                         placeholder="Buscar por ID" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'idServicio', 'contains')"
                         placeholder="Buscar por ID" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'descripcion', 'contains')"
                         placeholder="Buscar por descripción" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'valorRef', 'contains')"
                         placeholder="Buscar por valor" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'tiempoRef', 'contains')"
                         placeholder="Buscar por tiempo" class="p-column-filter w-full">
              </th>
              <th></th>
          </tr>
      </ng-template>

      <ng-template pTemplate="body" let-servicio>
          <tr>
              <td>{{servicio.tipoUnidad}}</td>
              <td>{{servicio.idUnidad}}</td>
              <td>{{servicio.idServicio}}</td>
              <td>{{servicio.descripcion}}</td>
              <td>{{servicio.valorRef}}</td>
              <td>{{servicio.tiempoRef}}</td>
              <td>
                  <div class="flex gap-2">
                      <button pButton icon="pi pi-pencil" class="p-button-rounded p-button-warning p-button-sm"
                              (click)="showDialog(servicio)"></button>
                      <button pButton icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-sm"
                              (click)="deleteServicio(servicio)"></button>
                  </div>
              </td>
          </tr>
      </ng-template>
  </p-table>

  <p-dialog [(visible)]="dialogVisible" [style]="{width: '50vw'}" [modal]="true"
            [header]="isNew ? 'Nuevo Servicio' : 'Editar Servicio'">
      <div class="grid grid-cols-2 gap-4">
          <div class="col-span-1">
              <label class="block mb-2">Tipo de Unidad *</label>
              <p-dropdown [options]="tipoUnidadOptions  " [(ngModel)]="servicio.tipoUnidad"
                        [disabled]="!isNew" placeholder="Seleccione tipo" class="w-full"
                        [style]="{ height: '2.6rem', width: '100%' }"></p-dropdown>
          </div>
          <div class="col-span-1">
              <label class="block mb-2">ID Unidad *</label>
              <p-dropdown id="idUnidad" [(ngModel)]="servicio.idUnidad" [options]="catalogo" [showClear]="true" [filter]="true"
              filterPlaceholder="Buscar ID Unidad" class="w-full p-dropdown-sm"
              [style]="{ height: '2.6rem', width: '100%' }" >
              </p-dropdown>


          </div>
          <div class="col-span-1" *ngIf="!isNew">
              <label class="block mb-2">ID Servicio</label>
              <input pInputText type="number" [(ngModel)]="servicio.idServicio" class="w-full" disabled />
          </div>
          <div class="col-span-2">
              <label class="block mb-2">Descripción *</label>
              <input pInputText [(ngModel)]="servicio.descripcion" class="w-full" required />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Valor de Referencia</label>
              <p-inputNumber [(ngModel)]="servicio.valorRef" mode="decimal" class="w-full"></p-inputNumber>
          </div>
          <div class="col-span-1">
              <label class="block mb-2">ID Moneda</label>
              <input pInputText [(ngModel)]="servicio.idMoneda" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Tiempo de Referencia</label>
              <p-inputNumber [(ngModel)]="servicio.tiempoRef" mode="decimal" class="w-full"></p-inputNumber>
          </div>
      </div>

      <ng-template pTemplate="footer">
          <button pButton label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="dialogVisible = false"></button>
          <button pButton label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveServicio()"></button>
      </ng-template>
  </p-dialog>
</div>
