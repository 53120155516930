import { ServicioGarantia } from './models/servicios-garantia.model';
import { UnidadesVendidasComponent } from './components/unidades-vendidas/unidades-vendidas.component';
import { HomeComponent } from './components/home/home.component';
// src/app/app.routes.ts
import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { authenticatedGuard } from './guards/authenticated.guard';
import { RoleGuard } from './guards/role-guard.guard';
import { CallbackComponent } from './callback/callback.component';
import { UnidadCrudComponent } from './components/unidades/unidad-crud/unidad-crud.component';
import { LoginComponent } from './auth/login/login.component';
import { AgenciasComponent } from './components/catalogo/agencias/agencias.component';
import { CatalogoComponent } from './components/catalogo/catalogo/catalogo.component';
// import { ServiciosComponent } from './components/catalogo/servicios/servicios.component';
import { HistorialServicioComponent } from './components/historial-servicio/historial-servicio.component';
import { TiposUnidadComponent } from './components/tipos-unidad/tipos-unidad.component';
import { AcuerdosComponent } from './components/acuerdos/acuerdos.component';
import { ProveedoresComponent } from './components/proveedores/proveedores.component';
import { ServiciosGarantiaComponent } from './components/servicios-garantia/servicios-garantia.component';
import { CatalogoTareasComponent } from './components/catalogo-tareas/catalogo-tareas.component';
import { TareasServiciosComponent } from './components/tareas-servicios/tareas-servicios.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'auth/callback',
    component: CallbackComponent
  },
  {
    path: 'home',
    canActivate: [authGuard],
    component: HomeComponent,
  },
  {
    path: 'login',
    canActivate: [authenticatedGuard],
    component: LoginComponent
  },
  {
    path: 'maestro-unidades/:vin',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
    component: UnidadCrudComponent,
  },
  {
    path: 'maestro-unidades-vendidas/vin/:vin',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
    component: UnidadesVendidasComponent
  },
  {
    path: 'maestro-unidades-vendidas/dominio/:dominio',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
    component: UnidadesVendidasComponent
  },
  {
     path: 'maestro-unidades-vendidas',
     canActivate: [authGuard, RoleGuard],
     data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
     component: UnidadesVendidasComponent
  },
  {
    path: 'historial-servicio/:dominio',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
    component: HistorialServicioComponent
  },
  {
    path: 'historial-servicio',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
    component: HistorialServicioComponent
  },
  // {
  //   path: 'historial-servicio/create/:dominio',
  //   canActivate: [authGuard, RoleGuard],
  //   data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
  //   component: HistorialServicioComponent
  // },
  // {
  //   path: 'servicios',
  //   canActivate: [authGuard, RoleGuard],
  //   data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
  //   component: ServiciosComponent
  // },
  {
    path: 'catalogo',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: CatalogoComponent
  },
  {
    path: 'tipos-unidad',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: TiposUnidadComponent
  },
  {
    path: 'acuerdos',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: AcuerdosComponent
  },{
    path: 'proveedores',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: ProveedoresComponent
  },
  {
    path: 'agencias',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: AgenciasComponent
  },
  {
    path: 'maestro-unidades',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: UnidadCrudComponent,
  },
  {
    path: 'servicios-garantia',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: ServiciosGarantiaComponent,
  },
  {
    path: 'catalogo-tareas',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: CatalogoTareasComponent,
  },
  {
    path: 'tareas-servicios',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: TareasServiciosComponent,
  },
];
