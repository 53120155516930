// proveedores.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Proveedor } from '../models/proveedor.model';

@Injectable({
  providedIn: 'root'
})
export class ProveedoresService {
  private baseUrl: string = environment.baseUrl + '/api/proveedores';

  constructor(private http: HttpClient) {}

  getAll(): Observable<Proveedor[]> {
    return this.http.get<Proveedor[]>(this.baseUrl);
  }

  getById(tipoProveedor: string, idProveedor: number): Observable<Proveedor> {
    return this.http.get<Proveedor>(`${this.baseUrl}/${tipoProveedor}/${idProveedor}`);
  }

  getByTipo(tipoProveedor: string): Observable<Proveedor[]> {
    return this.http.get<Proveedor[]>(`${this.baseUrl}/tipo/${tipoProveedor}`);
  }

  create(proveedor: Proveedor): Observable<Proveedor> {
    return this.http.post<Proveedor>(this.baseUrl, proveedor);
  }

  update(tipoProveedor: string, idProveedor: number, proveedor: Proveedor): Observable<Proveedor> {
    return this.http.put<Proveedor>(`${this.baseUrl}/${tipoProveedor}/${idProveedor}`, proveedor);
  }

  delete(tipoProveedor: string, idProveedor: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${tipoProveedor}/${idProveedor}`);
  }

  searchProveedores(termino: string): Observable<Proveedor[]> {
    return this.http.get<Proveedor[]>(`${this.baseUrl}/buscar?termino=${termino}`);
  }
}
