import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentoHistorialServicio } from '../models/documento-historial-servicio.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentoHistorialServicioService {
  private apiUrl: string = environment.baseUrl + '/api/historial-servicio'; // Base URL del endpoint

  constructor(private http: HttpClient) {}

  /**
   * Obtener todos los documentos de un historial de servicio.
   * @param dominio - Dominio del vehículo.
   * @param fechaServicio - Fecha del servicio.
   */
  obtenerDocumentos(dominio: string, fechaServicio: string): Observable<DocumentoHistorialServicio[]> {
    const url = `${this.apiUrl}/${dominio}/${fechaServicio}/documentos`;
    return this.http.get<DocumentoHistorialServicio[]>(url);
  }

  /**
   * Subir un nuevo documento a un historial de servicio.
   * @param dominio - Dominio del vehículo.
   * @param fechaServicio - Fecha del servicio.
   * @param archivo - Archivo a subir (form-data).
   */
  subirDocumento(dominio: string, fechaServicio: string, archivo: File): Observable<DocumentoHistorialServicio> {
    const url = `${this.apiUrl}/${dominio}/${fechaServicio}/documentos`;
    const formData: FormData = new FormData();
    formData.append('archivo', archivo);

    // añade 2 valores al formData: description y tipoArchivo
    formData.append('descripcion', 'Descripcion del archivo');
    formData.append('tipoDocumento', 'ORDEN_SERVICIO');

    return this.http.post<DocumentoHistorialServicio>(url, formData);
  }

  /**
   * Eliminar un documento específico.
   * @param dominio - Dominio del vehículo.
   * @param fechaServicio - Fecha del servicio.
   * @param idDocumento - ID del documento a eliminar.
   */
  eliminarDocumento(dominio: string, fechaServicio: string, idDocumento: number): Observable<void> {
    const url = `${this.apiUrl}/${dominio}/${fechaServicio}/documentos/${idDocumento}`;
    return this.http.delete<void>(url);
  }

  /**
   * Descargar un archivo de un documento específico.
   * @param dominio - Dominio del vehículo.
   * @param fechaServicio - Fecha del servicio.
   * @param idDocumento - ID del documento.
   */
  descargarDocumento(dominio: string, fechaServicio: string, idDocumento: number): Observable<Blob> {
    const url = `${this.apiUrl}/${dominio}/${fechaServicio}/documentos/${idDocumento}`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
