import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TiposUnidadService } from '../../../services/tipos-unidad.service';
import { CatalogoService } from '../../../services/catalogo.service';
import { Catalogo } from '../../../models/catalogo.model';
import { MonedasService } from '../../../services/moneda.service';
import { Moneda } from '../../../models/moneda.model';
import { BaseComponent } from '../../../base/base.component';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class CatalogoComponent extends BaseComponent implements OnInit {

  monedasOptions: { label: string, value: any }[] = []; // Opciones para el dropdown de Monedas
  catalogs: Catalogo[] = [];
  selectedCatalog: Catalogo | null = null;
  catalog: Catalogo = this.getEmptyCatalog();
  dialogVisible = false;
  loading = false;
  globalFilter: string = '';
  editingRow: boolean = false;
  tipoUnidadOptions: { label: string, value: any }[] = [];

  constructor(
    private catalogoService: CatalogoService,
    private monedasService: MonedasService,
    private tiposUnidadService: TiposUnidadService,
    private confirmationService: ConfirmationService
  ) { super(); }

  ngOnInit() {
    this.loadCatalogs();
    this.loadMonedas();
    this.loadTiposUnidad();
  }

// Método en un componente para cargar opciones de monedas
loadMonedas(): void {
  this.monedasService.getAllMonedas().subscribe(
    (data: Moneda[]) => {
      // console.log(data);

      this.monedasOptions = data.map(moneda => ({
        label: `${moneda.nombre} (${moneda.simbolo})`,
        value: moneda.idMoneda
      }));
    },
    (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar monedas' });
    }
  );
}


  loadTiposUnidad(): void {
    this.tiposUnidadService.getAllTiposUnidad().subscribe(
      (data) => {
        // console.log('Tipos de unidad cargados:', data);
        this.tipoUnidadOptions = data.map(tipoUnidad => ({
          label: tipoUnidad.tipoUnidad,
          value: tipoUnidad.tipoUnidad
        }));
      },
      (error) => {
        this.handleError(error);
      }
    );
  }


  loadCatalogs() {
    this.loading = true;
    this.catalogoService.getAllItems().subscribe({
      next: (data) => {
        this.catalogs = data;
        this.loading = false;
      },
      error: (error) => {
        this.handleError(error);
        this.loading = false;
      }
    });
  }

  getEmptyCatalog(): Catalogo {
    return {
      tipoUnidad: '',
      idUnidad: 0,
      nombre: '',
      nombreChino: '',
      nombreIngles: '',
      unidadesEmbalaje: 0,
      stockMinimoRecomendado: 0,
      valorRef: 0,
      idMoneda: '',
      fechaRef: new Date(),
      idSeccion: 0,
      idSubseccion: 0,
      nombreSeccion: '',
      nombreSubseccion: '',
	  simboloMoneda: ''
    };
  }

  isNew: boolean = true;
  showDialog(catalog?: Catalogo) {
    if (catalog) {
      this.catalog = { ...catalog };
	  this.isNew = false;
    } else {
      this.catalog = this.getEmptyCatalog();
      this.isNew = true;
    }
    this.dialogVisible = true;
  }

  saveCatalog() {
    if (!this.isNew) {
      this.catalogoService.updateItem(this.catalog.tipoUnidad, this.catalog.idUnidad, this.catalog).subscribe({
        next: (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Catálogo actualizado correctamente'
          });
          this.loadCatalogs();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    } else {
      this.catalogoService.createItem(this.catalog.tipoUnidad, this.catalog.idUnidad, this.catalog).subscribe({
        next: (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Catálogo creado correctamente'
          });
          this.loadCatalogs();
          this.dialogVisible = false;
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    }
  }

  deleteCatalog(catalog: Catalogo) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar este catálogo?',
      accept: () => {
        this.catalogoService.deleteItem(catalog.tipoUnidad, catalog.idUnidad).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Catálogo eliminado correctamente'
            });
            this.loadCatalogs();
          },
          error: (error) => {
            this.handleError(error);
          }
        });
      }
    });
  }
}
