import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Factura } from '../models/factura-comercial.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacturaService {
  private baseUrl: string = `${environment.baseUrl}/api/factura-comercial`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<Factura[]> {
    return this.http.get<Factura[]>(this.baseUrl);
  }

  getById(id: string): Observable<Factura> {
    return this.http.get<Factura>(`${this.baseUrl}/${id}`);
  }

  create(factura: Factura): Observable<Factura> {
    return this.http.post<Factura>(this.baseUrl, factura);
  }

  update(id: string, factura: Factura): Observable<Factura> {
    return this.http.put<Factura>(`${this.baseUrl}/${id}`, factura);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  // Obtener facturas comerciales asociadas a un Bill of Landing por parámetro
  getFacturaComercialByBL(param: string): Observable<Factura[]> {
    return this.http.get<Factura[]>(`${environment.baseUrl}/api/factura-comercial/bl/${param}`);
  }

}
