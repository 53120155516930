<!-- agencias.component.html -->
<div class="card p-4">
  <p-toast></p-toast>
  <p-confirmDialog></p-confirmDialog>

  <div class="flex justify-between items-center mb-4">
      <h2 class="text-2xl font-bold">Gestión de Agencias</h2>
      <button pButton label="Nueva Agencia" icon="pi pi-plus" class="p-button-primary" (click)="showDialog()"></button>
  </div>

  <p-table
      #dt
      [value]="agencias"
      [rows]="10"
      [paginator]="true"
      [tableStyle]="{'min-width': '75rem'}"
      [loading]="loading"
      [rowHover]="true"
      dataKey="idAgencia"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
      [rowsPerPageOptions]="[10,25,50]">

      <ng-template pTemplate="header">
          <tr>
              <th pSortableColumn="nombre">Nombre <p-sortIcon field="nombre"></p-sortIcon></th>
              <th pSortableColumn="idAgencia">ID Agencia <p-sortIcon field="idAgencia"></p-sortIcon></th>
              <th pSortableColumn="cuitCuil">CUIT/CUIL <p-sortIcon field="cuitCuil"></p-sortIcon></th>
              <th pSortableColumn="correo">Correo <p-sortIcon field="correo"></p-sortIcon></th>
              <th pSortableColumn="movil">Móvil <p-sortIcon field="movil"></p-sortIcon></th>
              <th pSortableColumn="provincia">Provincia <p-sortIcon field="provincia"></p-sortIcon></th>
              <th pSortableColumn="localidad">Localidad <p-sortIcon field="localidad"></p-sortIcon></th>
              <th pSortableColumn="ponderacion">Ponderación <p-sortIcon field="ponderacion"></p-sortIcon></th>
              <th>Acciones</th>
          </tr>
          <tr>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'nombre', 'contains')"
                         placeholder="Buscar por nombre" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="number" (input)="dt.filter($any($event.target)?.value, 'idAgencia', 'contains')"
                         placeholder="Buscar por ID Agencia" class="p-column-filter w-full">
              <th>
                    <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'cuitCuil', 'contains')"
                       placeholder="Buscar por CUIT/CUIL" class="p-column-filter w-full" pInputMask="99-99999999-9">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'correo', 'contains')"
                         placeholder="Buscar por correo" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'movil', 'contains')"
                         placeholder="Buscar por móvil" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'provincia', 'contains')"
                         placeholder="Buscar por provincia" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'localidad', 'contains')"
                         placeholder="Buscar por localidad" class="p-column-filter w-full">
              </th>
              <th>
                  <input pInputText type="number" (input)="dt.filter($any($event.target)?.value, 'ponderacion', 'contains')"
                         placeholder="Buscar por ponderación" class="p-column-filter w-full">
              </th>
              <th></th>
          </tr>
      </ng-template>

      <ng-template pTemplate="body" let-agencia>
          <tr>
              <td>{{agencia.nombre}}</td>
              <td>{{agencia.idAgencia}}</td>
              <td>{{agencia.cuitCuil}}</td>
              <td>{{agencia.correo}}</td>
              <td>{{agencia.movil}}</td>
              <td>{{agencia.provincia}}</td>
              <td>{{agencia.localidad}}</td>
              <td>{{agencia.ponderacion}}</td>
              <td>
                  <div class="flex gap-2">
                      <button pButton icon="pi pi-pencil" class="p-button-rounded p-button-warning p-button-sm"
                              (click)="showDialog(agencia)"></button>
                      <button pButton icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-sm"
                              (click)="deleteAgencia(agencia)"></button>
                  </div>
              </td>
          </tr>
      </ng-template>
  </p-table>

  <p-dialog [(visible)]="dialogVisible" [style]="{width: '50vw'}" [modal]="true"
            [header]="isNew ? 'Nueva Agencia' : 'Editar Agencia'">
      <div class="grid grid-cols-2 gap-4">
          <div class="col-span-2">
              <label class="block mb-2">Nombre *</label>
              <input pInputText [(ngModel)]="agencia.nombre" class="w-full" required />
          </div>
          <div class="col-span-1">
            <label class="block mb-2">ID Agencia *</label>
            <input pInputText [(ngModel)]="agencia.idAgencia"
                   class="w-full"
                   required
                   [disabled]="!isNew" />
          </div>          
          <div class="col-span-1">
              <label class="block mb-2">CUIT/CUIL *</label>
              <input pInputText [(ngModel)]="agencia.cuitCuil" class="w-full" required />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Móvil</label>
              <input pInputText [(ngModel)]="agencia.movil" class="w-full" />
          </div>
          <div class="col-span-2">
              <label class="block mb-2">Correo Electrónico</label>
              <input pInputText [(ngModel)]="agencia.correo" class="w-full" type="email" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Calle</label>
              <input pInputText [(ngModel)]="agencia.calle" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Número</label>
              <input pInputText [(ngModel)]="agencia.numero" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Provincia</label>
              <input pInputText [(ngModel)]="agencia.provincia" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Localidad</label>
              <input pInputText [(ngModel)]="agencia.localidad" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Código Postal</label>
              <input pInputText [(ngModel)]="agencia.codPostal" class="w-full" />
          </div>
          <div class="col-span-1">
              <label class="block mb-2">Ponderación</label>
              <input pInputText [(ngModel)]="agencia.ponderacion" type="number" class="w-full" />
          </div>
          <div class="col-span-2">
              <label class="block mb-2">Comentarios</label>
              <textarea pInputTextarea [(ngModel)]="agencia.comentarios" [rows]="3" class="w-full"></textarea>
          </div>
      </div>

      <ng-template pTemplate="footer">
          <button pButton label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="dialogVisible = false"></button>
          <button pButton label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveAgencia()"></button>
      </ng-template>
  </p-dialog>
</div>
