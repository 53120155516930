import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Agencia } from '../models/agencias.model';

@Injectable({
  providedIn: 'root'
})
export class AgenciasService {
  private baseUrl: string = environment.baseUrl + '/api/agencias';

  constructor(private http: HttpClient) {}

  getAll(): Observable<Agencia[]> {
    return this.http.get<Agencia[]>(this.baseUrl);
  }

    /**
   * Obtener una agencia por ID
   * @param id ID de la agencia
   * @returns Observable con la agencia
   */
    getById(id: number): Observable<Agencia> {
      return this.http.get<Agencia>(`${this.baseUrl}/${id}`);
    }

    /**
     * Crear una nueva agencia
     * @param agencia Datos de la nueva agencia
     * @returns Observable con la respuesta
     */
    create(agencia: Agencia): Observable<Agencia> {
      return this.http.post<Agencia>(this.baseUrl, agencia);
    }

    /**
     * Actualizar una agencia existente
     * @param id ID de la agencia
     * @param agencia Datos actualizados
     * @returns Observable con la respuesta
     */
    update(id: number, agencia: Agencia): Observable<Agencia> {
      return this.http.put<Agencia>(`${this.baseUrl}/${id}`, agencia);
    }

    /**
     * Eliminar una agencia
     * @param id ID de la agencia
     * @returns Observable con la respuesta
     */
    delete(id: number): Observable<any> {
      return this.http.delete(`${this.baseUrl}/${id}`);
    }
}
