// base.component.ts
import { Directive, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ErrorHandlerService } from '../services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';

@Directive()
export abstract class BaseComponent {
  protected messageService = inject(MessageService);
  protected errorHandler = inject(ErrorHandlerService);

  protected handleError(
    error: HttpErrorResponse,
    customConfig?: {
      defaultMessage?: string;
      severity?: 'error' | 'warn' | 'info';
    }
  ): void {
    console.error('Processing error in component:', error);
    if (customConfig && customConfig.defaultMessage) {
      console.error('Custom message:', customConfig.defaultMessage);
    }
    this.errorHandler.handleError(error, this.messageService, customConfig);
  }

  protected showSuccess(detail: string, summary: string = 'Éxito'): void {
    this.messageService.add({
      severity: 'success',
      summary,
      detail,
      life: 5000
    });
  }

  protected showInfo(detail: string, summary: string = 'Información'): void {
    this.messageService.add({
      severity: 'info',
      summary,
      detail,
      life: 5000
    });
  }

  protected showWarning(detail: string, summary: string = 'Advertencia'): void {
    this.messageService.add({
      severity: 'warn',
      summary,
      detail,
      life: 5000
    });
  }
}
